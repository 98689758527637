export class RfidDto {
  serialNumber: string;
  name: string;
  createUser: string;
  associatedUser: string;
  RFIDType: number;
  wallBoxes: string[];

  constructor(
    serialNumber: string,
    name: string,
    createUser: string,
    associatedUser: string,
    RFIDType: number,
    wallBoxes: string[] = []
  ) {
    this.serialNumber = serialNumber;
    this.name = name;
    this.createUser = createUser;
    this.associatedUser = associatedUser;
    this.RFIDType = RFIDType;
    this.wallBoxes = wallBoxes;
  }
}

export interface RFIDType {
  [key: string]: string;
}
export const rfidType: RFIDType = {
  0: "Anonymous",
  1: "Named",
};
