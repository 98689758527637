/** @format */

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { TableComponent } from "../../Components";
import {
  Data,
  OtaHeadCells,
  createOtaData,
} from "../../Components/TableComponent/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import { DeprecateOtaService } from "../../Services";
import { DeprecateOtaRequest, IOta, IPageProps } from "../../Models";

const DeprecateOta = (props: IPageProps) => {
  const deprecateOtaService = new DeprecateOtaService();
  const [otas, setOtas] = useState<IOta[]>([]);
  const [deviceProfiles, setDeviceProfiles] = useState<string[]>([]);
  const [deprecatedOtas, setDeprecatedOtas] = useState<IOta[]>([]);
  const [otaByDeviceProfile, setOtaByDeviceProfile] = useState<Data[][]>([]);
  const [deprecatePage, setDeprecatePage] = useState("tab");

  const cleanOtas: () => IOta[] = () => {
    return otas.filter(
      (ota) =>
        deprecatedOtas.filter(
          (dep) =>
            dep.deviceProfile === ota.deviceProfile &&
            dep.versionEsp === ota.versionEsp &&
            dep.versionStm === ota.versionStm
        ).length === 0
    );
  };

  const confirmCallback = async () => {
    props.setIsLoading(true);
    try {
      if (otas.length === 0) {
        throw new Error("You have to insert at least one ota");
      }
      const otaToDeprecate = cleanOtas();
      if (otaToDeprecate.length === 0) {
        throw new Error("All the otas are already deprecated");
      }
      await deprecateOtaService.Send(new DeprecateOtaRequest(otaToDeprecate));
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
      await getDeprecatedOta();
      setOtas([]);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  async function getDeviceProfilesCall() {
    try {
      props.setIsLoading(true);
      const response: any = await deprecateOtaService.GetDeviceProfileNames();
      setDeviceProfiles(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  async function getDeprecatedOta() {
    try {
      props.setIsLoading(true);
      const response: any = await deprecateOtaService.GetOtaDeprecated();
      setDeprecatedOtas(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  useEffect(() => {
    getDeprecatedOta();
    getDeviceProfilesCall();
    setOtas([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!deprecatedOtas || !deviceProfiles) {
      return;
    }
    var tmp: Data[][] = [];
    deviceProfiles.forEach((dev) => {
      const otaToAdd = deprecatedOtas.filter(
        (ota) => ota.deviceProfile === dev
      );
      if (otaToAdd.length !== 0) {
        tmp = [otaToAdd.map((o) => createOtaData(o)), ...tmp];
      }
    });
    setOtaByDeviceProfile(tmp);
  }, [deviceProfiles, deprecatedOtas]);

  const add = async () => {
    setOtas([...otas, { deviceProfile: "", versionEsp: "", versionStm: "" }]);
  };

  const remove = (index: number) => {
    const newOtas = otas.filter((_, i) => i !== index);
    setOtas(newOtas);
  };
  const updateOtas = (
    indexToSet: number,
    propertyName: string,
    newValue: string
  ) => {
    setOtas((current: IOta[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          return { ...obj, [propertyName]: newValue };
        }
        return obj;
      })
    );
  };

  const click = async () => {
    await confirmCallback();
  };

  const deleteOtas = async (selected: readonly string[]) => {
    props.setIsLoading(true);
    try {
      const tmp = deprecatedOtas.filter((dto) =>
        selected.includes(
          `${dto.deviceProfile}${dto.versionEsp}${dto.versionStm}`
        )
      );
      await deprecateOtaService.DeleteOtaDeprecated(
        new DeprecateOtaRequest(tmp)
      );
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
    await getDeprecatedOta();
  };

  return (
    <div>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          variant={deprecatePage === "tab" ? "contained" : "outlined"}
          onClick={() => setDeprecatePage("tab")}
        >
          DEPRECATED
        </Button>
        <Button
          variant={deprecatePage === "add" ? "contained" : "outlined"}
          onClick={() => setDeprecatePage("add")}
          disabled={!props.isAdmin}
        >
          TO DEPRECATE
        </Button>
      </ButtonGroup>

      {deprecatePage === "tab" ? (
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          {otaByDeviceProfile.map((otas, index) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {otas[0].firstElement} {otas.length}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableComponent
                  rows={otas}
                  headCells={OtaHeadCells}
                  selectionIcon={props.isAdmin ? <DeleteIcon /> : null}
                  selectionAction={props.isAdmin ? deleteOtas : null}
                  rowAction={null}
                  rowActionIcon={null}
                  tabTitle={"Deprecated Otas"}
                  reload={getDeprecatedOta}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <div>
          <IconButton
            onClick={add}
            sx={{
              width: "240px",
              height: "60px",
              fontSize: "15px",
              borderRadius: "10px",
            }}
            color="primary"
          >
            <label
              style={{
                marginRight: "10px",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
            >
              ota to deprecate
            </label>
            <AddCircleRoundedIcon fontSize="large"></AddCircleRoundedIcon>
          </IconButton>
          <br />
          <div style={{ maxWidth: "900px", marginTop: "30px" }}>
            {otas?.map((ota, index) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <TextField
                      select
                      label="DeviceProfile"
                      onChange={(event) =>
                        updateOtas(index, "deviceProfile", event.target.value)
                      }
                      value={ota.deviceProfile}
                      sx={{ minWidth: "200px", mr: "10px" }}
                    >
                      {deviceProfiles.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <TextField
                    onChange={(event) =>
                      updateOtas(index, "versionEsp", event.target.value)
                    }
                    value={ota.versionEsp}
                    label="Versione ESP"
                    variant="outlined"
                    sx={{ mr: "10px" }}
                  />
                  <TextField
                    onChange={(event) =>
                      updateOtas(index, "versionStm", event.target.value)
                    }
                    value={ota.versionStm}
                    label="Versione STM"
                    variant="outlined"
                  />
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{ border: "1px solid #223AA3" }}
                    aria-label="delete"
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <br />
              </div>
            ))}
          </div>
          <br></br>
          <Button
            sx={{ width: 200, p: 1, m: 2, float: "right", mr: "2rem" }}
            onClick={click}
            variant="outlined"
          >
            Run
          </Button>
        </div>
      )}
    </div>
  );
};

export default DeprecateOta;
