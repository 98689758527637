import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import React from "react";

function FooterComponent() {
  return (
    <Box
      sx={{
        ml: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>Version {process.env.REACT_APP_VERSION}</Typography>
    </Box>
  );
}

export default FooterComponent;
