/** @format */

import React from "react";
import TableComponent from "../../Components/TableComponent/TableComponent";
import {
  NetworksHeadCells,
  Data,
  createDataFromNetworks,
  NetworksInMaintenanceHeadCells,
  createNetworkInMaintenanceData,
} from "../../Components/TableComponent/utils";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import {
  IPageProps,
  NetworkDto,
  NetworksInMaintenanceDto,
  ServiceResponseDto,
} from "../../Models";
import { NetworkService } from "../../Services";
import { Box, ButtonGroup, Slider, Typography } from "@mui/material";
import UserAutoCompleteSelect from "../../Components/AutoCompleteSelects/UserAutoCompleteSelect/UserAutoCompleteSelect";

function Networks(props: IPageProps) {
  const [currentTab, setCurrentTab] = React.useState("NetworksInMaintenance");
  const [networkRows, setNetworkRows] = React.useState<Data[]>([]);
  const [networkInMaintenanceRows, setNetworkInMaintenanceRows] =
    React.useState<Data[]>([]);
  const [hour, setHour] = React.useState<number>(2);
  const [netId, setNetId] = React.useState<string>("");
  const [openPopup, setOpenPopup] = React.useState(false);
  const [userUid, setUserUid] = React.useState<string>("");

  const service = new NetworkService();

  const hoursEntry = Array(25)
    .fill(1)
    .map((e, i) => {
      return { label: `${i}H`, value: i };
    });
  const handleClose = () => {
    setOpenPopup(false);
  };

  const setTab = (newTab: string) => {
    if (newTab === currentTab) {
      return;
    }
    if (newTab === "Networks") {
      setCurrentTab("Networks");
    } else if (newTab === "NetworksInMaintenance") {
      setCurrentTab("NetworksInMaintenance");
    }
  };

  async function getNetworksWithoutAdmins() {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<NetworkDto[]> =
        await service.GetWithoutAdministrates();
      const responseData = response.data.map(
        (r: ServiceResponseDto<NetworkDto[]>) => createDataFromNetworks(r)
      );
      setNetworkRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  async function getNetworksInMaintenance() {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<NetworksInMaintenanceDto[]> =
        await service.GetNetworksInMaintenance(hour);
      const responseData = response.data.map(
        (r: ServiceResponseDto<NetworksInMaintenanceDto[]>) =>
          createNetworkInMaintenanceData(r)
      );
      setNetworkInMaintenanceRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  const fetchCurrentTabData = () => {
    if (currentTab === "Networks") {
      getNetworksWithoutAdmins();
    } else if (currentTab === "NetworksInMaintenance") {
      getNetworksInMaintenance();
    }
  };
  React.useEffect(() => {
    fetchCurrentTabData();
  }, [currentTab, hour]);

  const assign = async () => {
    try {
      props.setIsLoading(true);
      await service.Assign(userUid, netId);
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
      await getNetworksWithoutAdmins();
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
    handleClose();
  };

  const onHourChange = (event: Event, newValue: number | number[]) => {
    setHour(newValue as number);
  };

  const onDeleteNetworkInMaintenance = async (row: any) => {
    var assetId = networkInMaintenanceRows.filter(
      (d) => d.firstElement === row
    )[0].id;
    try {
      props.setIsLoading(true);
      await service.DeleteNetworkInMaintenance(assetId);
      setNetworkInMaintenanceRows(
        networkInMaintenanceRows.filter((d) => d.firstElement !== row)
      );
      props.handleAlertShow("success", "Network has been unlocked");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant={
              currentTab === "NetworksInMaintenance" ? "contained" : "outlined"
            }
            onClick={() => setTab("NetworksInMaintenance")}
          >
            Networks In Maintenance
          </Button>
          <Button
            variant={currentTab === "Networks" ? "contained" : "outlined"}
            onClick={() => setTab("Networks")}
          >
            Orphaned Networks
          </Button>
        </ButtonGroup>
        {currentTab === "Networks" && (
          <TableComponent
            tabTitle="Orphaned Networks"
            rows={networkRows}
            headCells={NetworksHeadCells}
            selectionIcon={null}
            selectionAction={null}
            rowAction={
              props.isAdmin
                ? (selected: string) => {
                    setNetId(
                      networkRows.filter((d) => d.firstElement == selected)[0]
                        .id
                    );
                    setOpenPopup(true);
                  }
                : null
            }
            rowActionIcon={props.isAdmin ? <AddCircleRoundedIcon /> : null}
            reload={getNetworksWithoutAdmins}
          />
        )}
        {currentTab === "NetworksInMaintenance" && (
          <>
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom>Older than</Typography>
              <Slider
                aria-label="Older than"
                defaultValue={hour}
                max={24}
                min={0}
                step={1}
                marks={hoursEntry}
                valueLabelDisplay="auto"
                valueLabelFormat={(value: any) => `${value}H`}
                onChange={onHourChange}
              />
            </Box>
            <TableComponent
              tabTitle="Networks In Maintenance"
              rows={networkInMaintenanceRows}
              headCells={NetworksInMaintenanceHeadCells}
              selectionIcon={null}
              selectionAction={null}
              rowAction={onDeleteNetworkInMaintenance}
              rowActionIcon={props.isAdmin ? <LockOpenIcon /> : null}
              reload={getNetworksInMaintenance}
            />
          </>
        )}
        {openPopup && (
          <div>
            <Dialog
              open={openPopup}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle id="alert-dialog-title">
                {"Scegli un utente"}
              </DialogTitle>
              <UserAutoCompleteSelect
                disabled={false}
                label="Utente"
                handleAlertShow={props.handleAlertShow}
                paramKey={"id"}
                setParam={setUserUid}
                styleToRender={{ padding: "10px", marginTop: "7px" }}
              />
              <DialogActions>
                <Button onClick={assign}>Assegna</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Box>
    </>
  );
}
export default Networks;
