import { apiURL } from ".";
import { AddOtaToCPO, UpdateCPODto } from "../Models";
import CPODto from "../Models/CPODto";
import InsertCPODto from "../Models/InsertCPODto";
import ServiceResponseDto, {
  GenericServiceResponseDto,
} from "../Models/ServiceResponseDto";
import { AuthService } from "./AuthService";

export class CPOService {
  appAPIAuthService: AuthService = new AuthService(apiURL.appApiUrl);

  /*async GetUsersByName(value: string): Promise<UserDto[]> {
    var res: ServiceResponseDto<UserDto[]> = await this.authService.GetAsync<
      UserDto[]
    >(`${UserControllerUrl}/GetUsers?textSearch=${value}`);
    return res;
  }*/
  async GetCPOs(): Promise<ServiceResponseDto<CPODto[]>> {
    var res: ServiceResponseDto<CPODto[]> =
      await this.appAPIAuthService.GetAsync<ServiceResponseDto<CPODto[]>>(
        `cpos`
      );
    return res;
  }
  async CreateCPO(cpo: InsertCPODto): Promise<GenericServiceResponseDto> {
    var res: GenericServiceResponseDto =
      await this.appAPIAuthService.PostAsyncAndReturn<GenericServiceResponseDto>(
        `cpos/`,
        cpo
      );
    return res;
  }
  async UpdateCPO(
    name: string,
    cpo: UpdateCPODto
  ): Promise<GenericServiceResponseDto> {
    var res: GenericServiceResponseDto =
      await this.appAPIAuthService.PatchAsync<GenericServiceResponseDto>(
        `cpos/${name}`,
        cpo
      );
    return res;
  }
  async AddDeviceProfileToCPO(
    cpoName: string,
    deviceProfile: string,
    otaCPO: AddOtaToCPO
  ): Promise<GenericServiceResponseDto> {
    var res: GenericServiceResponseDto =
      await this.appAPIAuthService.PutAsyncAndReturn<GenericServiceResponseDto>(
        `cpos/${cpoName}/deviceProfile/${deviceProfile}`,
        otaCPO
      );
    return res;
  }
  async AddDeviceProfileToDazeCPO(
    otaCPO: AddOtaToCPO
  ): Promise<GenericServiceResponseDto> {
    var res: GenericServiceResponseDto =
      await this.appAPIAuthService.PutAsyncAndReturn<GenericServiceResponseDto>(
        `dazeOtas`,
        otaCPO
      );
    return res;
  }
  async GetUnsupportedCPODevices(
    cpoName: string,
    includeInvalidDeviceProfiles: boolean
  ): Promise<ServiceResponseDto<string[]>> {
    var res: ServiceResponseDto<string[]> =
      await this.appAPIAuthService.GetAsync<ServiceResponseDto<string[]>>(
        `cpos/${cpoName}/unsupportedDevices?includeInvalidDeviceProfiles=${includeInvalidDeviceProfiles}`
      );
    return res;
  }
}
