import React, { useEffect, useState } from "react";
import IPageProps from "../../Models/IPageProps";
import { AttributesService, NetworkService } from "../../Services";

import IconButton from "@mui/material/IconButton/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  AddOtaToCPO,
  CPODto,
  DataDto,
  DeviceProfileDto,
  FirmwareUpdateMode,
  FwsDto,
  NetworkDto,
  OtaUpdateResponseStatus,
  UpdateDeviceRequestDto,
} from "../../Models";
import { OtaService } from "../../Services/OtaService";
import {
  DialogComponent,
  TableComponent,
  UpdateProgressOverlay,
  useInterval,
} from "../../Components";
import GetNumSupportedOtaDevicesDto from "../../Models/GetNumSupportedOtaDevicesDto";
import AutoCompleteSelect from "../../Components/AutoCompleteSelects/AutoCompletSelect";
import OtaUpdateStatusDto from "../../Models/OtaUpdateStatusDto";
import {
  Data,
  DeviceFirmwareHeadCell,
  createDeviceData,
} from "../../Components/TableComponent/utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ServiceResponseDto from "../../Models/ServiceResponseDto";
import { CPOService } from "../../Services/CPOService";
import { WallBoxService } from "../../Services/WallBoxService";
import AddOtaToDazeCPO from "../../Models/AddOtaToDazeCPO";

function FirmwareUpdate(props: IPageProps) {
  const [firmwareList, setFirmwareList] = useState<FwsDto[]>([]);
  const [softwareList, setSoftwareList] = useState<FwsDto[]>([]);
  const [networkList, setNetworkList] = useState<NetworkDto[]>([]);
  const [cposList, setCposList] = useState<CPODto[]>([]);
  const [selectedFirmware, setSelectedFirmware] = useState<string>("");
  const [selectedSoftware, setSelectedSoftware] = useState<string>("");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");
  const [updateDeviceFromFile, setUpdateDeviceFromFile] =
    useState<boolean>(false);
  const [updateDeviceMassive, setUpdateDeviceMassive] =
    useState<boolean>(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [rows, setRows] = useState<Data[]>([]);
  const [selectedCpo, setSelectedCpo] = useState<string>("");
  const [selectedDeviceProfile, setSelectedDeviceProfile] =
    useState<string>("");
  const [deviceProfileList, setDeviceProfileList] = useState<
    DeviceProfileDto[]
  >([]);
  const [updateMode, setUpdateMode] = useState<FirmwareUpdateMode>(
    FirmwareUpdateMode.UpdateDevices
  );
  const [pollingInterval, setPollingInterval] = useState<number>(5);

  const [deviceId, setDeviceId] = useState<string>("");
  const [deviceSerials, setDeviceSerials] = useState<string[]>([]);
  const [devicesList, setDevicesList] = useState<DataDto[]>([]);
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>(
    "Are you sure you want to perform an update?"
  );
  const [otaStatus, setOtaStatus] = useState<OtaUpdateStatusDto | null>(null);
  const [currentTab, setCurrentTab] = React.useState("ManualUpdate");

  const otaService = new OtaService();
  const networkService = new NetworkService();
  const attributeService = new AttributesService();
  const cpoService = new CPOService();
  const fileReader = new FileReader();

  const setTab = (newTab: string) => {
    if (newTab === currentTab) {
      return;
    }
    
    cleanUpState();
    
    if (newTab === "ManualUpdate") {
      setCurrentTab("ManualUpdate");
      setUpdateMode(FirmwareUpdateMode.UpdateDevices);
    } else if (newTab === "MassiveUpdate") {
      setCurrentTab("MassiveUpdate");
      setUpdateMode(FirmwareUpdateMode.UpdateByCpoName);
    }
  };

  const getModeArgumentForUpdateMode = (selectedDeviceProfile: string) => {
    if (updateMode === FirmwareUpdateMode.UpdateNetwork) {
      return `${networkList.find((x) => x.id == selectedNetwork)?.uid},${
        deviceProfileList.find((x) => x.id === selectedDeviceProfile).name
      }`;
    } else if (updateMode === FirmwareUpdateMode.UpdateByCpoName) {
      return `${selectedCpo},${
        deviceProfileList.find((x) => x.id === selectedDeviceProfile).name
      }`;
    } else if (updateMode === FirmwareUpdateMode.UpdateByDaze) {
      return deviceProfileList.find((x) => x.id === selectedDeviceProfile).name;
    }
    return "";
  };
  const updateDevices = async () => {
    try {
      props.setIsLoading(true);
      console.log(
        `Updating device ${deviceId} with firmware ${selectedFirmware} software ${selectedSoftware}`
      );
      //Update is always considered massive if we are updating one or more devices inside a CPO
      var isMassiveUpdate =
        currentTab === "MassiveUpdate" ? true : updateDeviceMassive;
      var updateRequest: UpdateDeviceRequestDto = {
        modeParameter: getModeArgumentForUpdateMode(selectedDeviceProfile),
        wallBoxes:
          updateMode === FirmwareUpdateMode.UpdateDevices ? deviceSerials : [],
        mode: updateMode,
        firmwareId: selectedFirmware,
        softwareId: selectedSoftware,
        isManualUpdate: !isMassiveUpdate,
      };
      var res = await otaService.UpdateDevices(updateRequest);
      props.handleAlertShow("success", "Update has been queued");
      await checkUpdateStatus();
      setPollingInterval(5);
    } catch (error: any) {
      if (error.response) {
        //Parse our response and status
        props.handleAlertShow("error", error.response.data.message);
      } else {
        props.handleAlertShow("error", error.message);
      }
    } finally {
      props.setIsLoading(false);
    }
  };

  const update = async () => {
    await updateDevices();
  };
  const fetchFirmwares = async () => {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<FwsDto[]> = await otaService.GetFws();
      const otaList = response.data;
      let fwList: FwsDto[] = [];
      let swList: FwsDto[] = [];
      otaList.forEach((firmware) => {
        if (firmware.type === "FIRMWARE") {
          fwList.push(firmware);
        } else {
          swList.push(firmware);
        }
      });
      setFirmwareList(fwList);
      setSoftwareList(swList);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchNetworks = async () => {
    try {
      props.setIsLoading(true);
      var response: ServiceResponseDto<NetworkDto[]> =
        await networkService.GetNetworks();
      setNetworkList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchDevices = async () => {
    try {
      props.setIsLoading(true);
      var response = await attributeService.GetData("Device");
      setDevicesList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchCpos = async () => {
    try {
      props.setIsLoading(true);
      var response: ServiceResponseDto<CPODto[]> = await cpoService.GetCPOs();
      response.data.push({
        name: "DAZE",
        displayName: "DAZE",
        visible: true,
      });
      setCposList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchDeviceProfiles = async () => {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<DeviceProfileDto[]> =
        await otaService.GetDeviceProfiles();
      setDeviceProfileList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const addDevice = () => {
    setDeviceSerials([...deviceSerials, ""]);
  };
  const removeId = (index: number) => {
    const newIds = deviceSerials.filter((_, i) => i !== index);
    setDeviceSerials(newIds);
  };
  const updateIds = (indexToSet: number, newValue: string) => {
    console.log("Updating ids: " + newValue);
    setDeviceSerials((current: string[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          return newValue;
        }
        return obj;
      })
    );
  };
  const getNumDeviceToUpdate = async (
    selectedDeviceProfile: string
  ): Promise<GetNumSupportedOtaDevicesDto | undefined> => {
    props.setIsLoading(true);
    try {
      var request: UpdateDeviceRequestDto = {
        mode: updateMode,
        modeParameter: getModeArgumentForUpdateMode(selectedDeviceProfile),
        firmwareId: selectedFirmware,
        softwareId: selectedSoftware,
        isManualUpdate: !updateDeviceMassive,
      };

      if (updateMode === FirmwareUpdateMode.UpdateDevices) {
        request.wallBoxes = deviceSerials;
      }

      const serviceResponse: ServiceResponseDto<GetNumSupportedOtaDevicesDto> =
        await otaService.GetNumUpdatableDevices(request);

      return serviceResponse.data;
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const onDialogConfirm = async () => {
    if (selectedFirmware === "" || selectedSoftware === "") {
      props.handleAlertShow("error", "Invalid Firmware selected");
      return;
    }
    var firmware = firmwareList.find((fw) => fw.id === selectedFirmware);
    var software = softwareList.find((sw) => sw.id === selectedSoftware);
    if (
      firmware == undefined ||
      firmware?.deviceProfileId !== software?.deviceProfileId
    ) {
      props.handleAlertShow("error", "Mismatched device profile");
      return;
    }
    debugger;
    setSelectedDeviceProfile(firmware?.deviceProfileId);

    if (
      updateMode === FirmwareUpdateMode.UpdateByCpoName ||
      updateMode === FirmwareUpdateMode.UpdateByDaze
    ) {
      //
      const deviceProfile = deviceProfileList.find(
        (d) => d.id === selectedDeviceProfile
      ).name;
      const stmVersion = firmware.version;
      const espVersion = software.version;
      if (selectedCpo === "DAZE") {
        await cpoService.AddDeviceProfileToDazeCPO({
          deviceProfile: deviceProfile,
          versionEsp: espVersion,
          versionStm: stmVersion,
        } as AddOtaToDazeCPO);
      } else {
        await cpoService.AddDeviceProfileToCPO(selectedCpo, deviceProfile, {
          esp32Version: espVersion,
          stmVersion: stmVersion,
        } as AddOtaToCPO);
      }
    }
    var otaDeviceUpdate = await getNumDeviceToUpdate(firmware?.deviceProfileId);
    if(otaDeviceUpdate === undefined) {
      return;
    }
    
    let textMessage = "";
    if(otaDeviceUpdate!.errorVersionsDevices.length > 0) {
      textMessage = otaDeviceUpdate!.errorVersionsDevices.map(x => x + ", ").join();
    }
    
    if (otaDeviceUpdate!.numDevices === 0) {
      textMessage = textMessage + ". No device(s) available to update";
      props.handleAlertShow("error", textMessage);
      return;
    }

    if(textMessage !== "") {
      props.handleAlertShow("warning", "Some devices don't have firmware or software versions informations: " + textMessage);
    }
    
    setDialogMessage(
      `The tool will update ${otaDeviceUpdate?.numDevices} device...are you sure you want to continue?`
    );

    setShowConfirmDialog(true);
  };
  useEffect(() => {
    if (updateMode === FirmwareUpdateMode.UpdateDevices) {
      fetchDevices();
    } else if (updateMode === FirmwareUpdateMode.UpdateNetwork) {
      fetchNetworks();
    }
    fetchFirmwares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMode]);

  useEffect(() => {
    //If no CPO is selected, avoid changing the update mode
    if (selectedCpo === "") {
      return;
    }
    if (selectedCpo === "DAZE") {
      setUpdateMode(FirmwareUpdateMode.UpdateByDaze);
    } else {
      setUpdateMode(FirmwareUpdateMode.UpdateByCpoName);
    }
  }, [selectedCpo]);

  useEffect(() => {
    if (currentTab === "MassiveUpdate") {
      fetchCpos();
    }
  }, [currentTab]);
  useEffect(() => {
    fetchDeviceProfiles();
  }, [0]);
  const checkUpdateStatus = async () => {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<OtaUpdateStatusDto> =
        await otaService.CheckOtaUpdateStatus();
      setOtaStatus(response.data);
    } catch (error: any) {
      setPollingInterval(0);
      setOtaStatus(null);
    } finally {
      props.setIsLoading(false);
    }
  };
  useInterval(async () => {
    await checkUpdateStatus();
  }, pollingInterval * 1000);

  const filterOtaList = (otaPackage: FwsDto): boolean => {
    if (currentTab === "MassiveUpdate") {
      if (selectedDeviceProfile !== otaPackage.deviceProfileId) {
        return false;
      }
    } else if (updateMode === FirmwareUpdateMode.UpdateDevices) {
      if (deviceSerials.length === 0) {
        return false;
      }
      var invalidOtaPackage = false;
      deviceSerials.forEach((element) => {
        var device = devicesList.find((device) => device.name === element);
        if (device?.deviceProfileId !== otaPackage.deviceProfileId) {
          invalidOtaPackage = true;
          return;
        }
      });
      if (invalidOtaPackage) {
        return false;
      }
    }
    return true;
  };
  const cleanUpState = () => {
    setSelectedDeviceProfile("");
    setSelectedFirmware("");
    setSelectedSoftware("");
    setSelectedNetwork("");
    setDeviceSerials([]);
    setSelectedCpo("");
    setRows([]);
    setFile(undefined);
    setFileName("");
  };
  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const tmp: string[] = csvRows.map((i) => {
      const values = i.split(";");
      const obj = csvHeader.reduce((object: any, header, index) => {
        return values[0].replace(/(\r\n|\n|\r)/gm, "");
      }, {});
      return obj;
    });
    var devices = tmp.filter((obj) => obj !== "");
    setDeviceSerials(devices);
    setRows(devices.map((a) => createDeviceData(a)));
  };

  const ReadFile = () => {
    if (file) {
      props.setIsLoading(true);
      fileReader.onload = function (event) {
        if (
          event !== null &&
          event.target !== null &&
          event.target.result !== null
        ) {
          const text: string = event.target.result.toString();
          csvFileToArray(text);
        }
      };

      fileReader.readAsText(file);
      props.setIsLoading(false);
    }
  };
  const onCSVChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const onCSVLoad = (e: any) => {
    e.preventDefault();
    ReadFile();
  };
  const removeDevice = (selected: readonly string[]) => {
    const filtered = deviceSerials.filter((item) => !selected.includes(item));
    const filteredRows = rows.filter(
      (item) => !selected.includes(item.firstElement)
    );
    setDeviceSerials(filtered);
    setRows(filteredRows);
  };
  return (
    <>
      {otaStatus !== null ? (
        <UpdateProgressOverlay otaStatus={otaStatus} />
      ) : (
        <>
          <>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                variant={
                  currentTab === "ManualUpdate" ? "contained" : "outlined"
                }
                onClick={() => setTab("ManualUpdate")}
              >
                Manual Update
              </Button>
              <Button
                variant={
                  currentTab === "MassiveUpdate" ? "contained" : "outlined"
                }
                onClick={() => setTab("MassiveUpdate")}
              >
                Massive Update
              </Button>
            </ButtonGroup>
            {currentTab === "ManualUpdate" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    mt: "50px",
                    p: "2",
                    height: "100%",
                  }}
                >
                  <FormControl sx={{ width: "48%" }} size="small">
                    <InputLabel id="update-mode-select-label">
                      Entity
                    </InputLabel>
                    <Select
                      label="Update Mode"
                      sx={{ padding: "2%" }}
                      labelId="update-mode-select-label"
                      id="update-mode-select"
                      onChange={(event) => {
                        setUpdateMode(Number(event.target.value));
                        cleanUpState();
                      }}
                      value={updateMode}
                    >
                      <MenuItem
                        key={0}
                        value={FirmwareUpdateMode.UpdateDevices}
                      >
                        {"Device"}
                      </MenuItem>
                      <MenuItem
                        key={1}
                        value={FirmwareUpdateMode.UpdateNetwork}
                      >
                        {"Network"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {updateMode === FirmwareUpdateMode.UpdateDevices && (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(event) => {
                            cleanUpState();
                            setUpdateDeviceFromFile(event.target.checked);
                          }}
                          checked={updateDeviceFromFile}
                        />
                      }
                      label="Update from file"
                    />
                  )}
                </Box>
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      mt: "60px",
                      alignItems: "center",
                    }}
                  >
                    {updateMode === FirmwareUpdateMode.UpdateNetwork && (
                      <FormControl sx={{ width: "49%" }} size="small">
                        <InputLabel id="network-select-label">
                          Network
                        </InputLabel>

                        <Select
                          labelId="network-select-label"
                          id="network-select"
                          label="Network"
                          onChange={(event) =>
                            setSelectedNetwork(event.target.value)
                          }
                          value={selectedNetwork}
                        >
                          {networkList.map((network) => {
                            return (
                              <MenuItem key={network.id} value={network.id}>
                                {network.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    {(selectedNetwork !== "" ||
                      selectedDeviceProfile !== "" ||
                      deviceSerials.some((id) => id !== "")) && (
                      <>
                        <FormControl sx={{ width: "49%" }} size="small">
                          <InputLabel id="firmware-select-label">
                            Firmware
                          </InputLabel>

                          <Select
                            labelId="firmware-select-label"
                            id="firmware-select"
                            label="Firmware"
                            onChange={(event) =>
                              setSelectedFirmware(event.target.value)
                            }
                            value={selectedFirmware}
                          >
                            {firmwareList
                              .filter(filterOtaList)
                              .map((firmware) => {
                                return (
                                  <MenuItem
                                    key={firmware.id}
                                    value={firmware.id}
                                  >
                                    {`${firmware.title} (${firmware.tag})`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ width: "49%" }} size="small">
                          <InputLabel id="software-select-label">
                            Software
                          </InputLabel>

                          <Select
                            labelId="software-select-label"
                            id="software-select"
                            label="Software"
                            onChange={(event) =>
                              setSelectedSoftware(event.target.value)
                            }
                            value={selectedSoftware}
                          >
                            {softwareList
                              .filter(filterOtaList)
                              .map((software) => {
                                return (
                                  <MenuItem
                                    key={software.id}
                                    value={software.id}
                                  >
                                    {`${software.title} (${software.tag})`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Box>
                  {updateMode === FirmwareUpdateMode.UpdateDevices && (
                    <>
                      {updateDeviceFromFile ? (
                        <>
                          <Button
                            onClick={onCSVChange}
                            sx={{
                              width: "180px",
                              height: "60px",
                              fontSize: "15px",
                              borderRadius: "10px",
                            }}
                            component="label"
                          >
                            <input
                              type="file"
                              accept=".csv"
                              onChange={onCSVChange}
                              onClick={(e: any) => (e.target.value = null)}
                              hidden
                            />
                            CHOOSE FILE
                            <AddCircleRoundedIcon
                              fontSize="medium"
                              sx={{ ml: "5px" }}
                            ></AddCircleRoundedIcon>
                          </Button>
                          <label>{fileName !== "" && fileName}</label>
                          <Tooltip title="Upload File">
                            <Button onClick={onCSVLoad} sx={{ ml: "10px" }}>
                              <CloudUploadIcon fontSize="medium" />
                            </Button>
                          </Tooltip>
                          {rows && rows.length > 0 && (
                            <>
                              <TableComponent
                                rows={rows}
                                headCells={DeviceFirmwareHeadCell}
                                selectionIcon={<RemoveCircleOutlineIcon />}
                                selectionAction={removeDevice}
                                rowAction={null}
                                rowActionIcon={null}
                                tabTitle={"Uploaded file"}
                                reload={ReadFile}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            mt: "30px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "35%" }}>
                            {deviceSerials?.map((idValue, index) => (
                              <div key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <AutoCompleteSelect
                                    paramKey="name"
                                    setParam={(val: string) =>
                                      updateIds(index, val)
                                    }
                                    handleAlertShow={props.handleAlertShow}
                                    disabled={false}
                                    label="Device"
                                    styleToRender={{
                                      width: "80%",
                                      marginRight: "20px",
                                    }}
                                    data={devicesList}
                                  />
                                  <IconButton
                                    aria-label="delete"
                                    sx={{ border: "1px solid #223AA3" }}
                                    color="primary"
                                    onClick={() => removeId(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                                <br />
                              </div>
                            ))}
                          </div>

                          <div style={{ width: "15%" }}>
                            <IconButton
                              onClick={addDevice}
                              sx={{
                                width: "180px",
                                height: "60px",
                                fontSize: "15px",
                                borderRadius: "10px",
                              }}
                              color="primary"
                            >
                              <label
                                style={{
                                  marginRight: "10px",
                                  textTransform: "uppercase",
                                  cursor: "pointer",
                                }}
                              >
                                Add Device{" "}
                              </label>
                              <AddCircleRoundedIcon fontSize="large"></AddCircleRoundedIcon>
                            </IconButton>
                          </div>
                        </Box>
                      )}
                    </>
                  )}
                </FormGroup>
                <Box sx={{ width: "100%", my: "25px", mb: "50px" }}>
                  <Button
                    sx={{ width: 200, p: 1, mx: 2, float: "right" }}
                    variant="outlined"
                    onClick={() => setShowWarningDialog(true)}
                  >
                    Run
                  </Button>
                  <FormControlLabel
                    sx={{ float: "right" }}
                    control={
                      <Checkbox
                        checked={updateDeviceMassive}
                        onChange={(event) => {
                          setUpdateDeviceMassive(event.target.checked);
                        }}
                      />
                    }
                    label="Do not block for future updates"
                  />
                </Box>
              </>
            )}
            {currentTab === "MassiveUpdate" && (
              <Box
                sx={{
                  mt: "60px",
                }}
              >
                <FormControl sx={{ width: "49%" }} size="small">
                  <InputLabel id="cpo-select-label">CPO</InputLabel>

                  <Select
                    labelId="cpo-select-label"
                    id="cpo-select"
                    label="CPO"
                    onChange={(event) => {
                      setSelectedCpo(event.target.value);
                    }}
                    value={selectedCpo}
                  >
                    {cposList.map((cpo) => {
                      return (
                        <MenuItem key={cpo.name} value={cpo.name}>
                          {cpo.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedCpo !== "" && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        mt: "30px",
                        alignItems: "center",
                      }}
                    >
                      <FormControl sx={{ width: "49%" }} size="small">
                        <InputLabel id="device-profile-select-label">
                          Device Profile
                        </InputLabel>

                        <Select
                          labelId="device-profile-select-label"
                          id="device-profile-select"
                          label="Device Profile"
                          onChange={(event) => {
                            setSelectedDeviceProfile(event.target.value);
                          }}
                          value={selectedDeviceProfile}
                        >
                          {deviceProfileList.map((deviceProfile) => {
                            return (
                              <MenuItem
                                key={deviceProfile.id}
                                value={deviceProfile.id}
                              >
                                {deviceProfile.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <>
                        <FormControl sx={{ width: "49%" }} size="small">
                          <InputLabel id="firmware-select-label">
                            Firmware
                          </InputLabel>

                          <Select
                            labelId="firmware-select-label"
                            id="firmware-select"
                            label="Firmware"
                            onChange={(event) =>
                              setSelectedFirmware(event.target.value)
                            }
                            value={selectedFirmware}
                          >
                            {firmwareList
                              .filter(filterOtaList)
                              .map((firmware) => {
                                return (
                                  <MenuItem
                                    key={firmware.id}
                                    value={firmware.id}
                                  >
                                    {`${firmware.title} (${firmware.tag})`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ width: "49%" }} size="small">
                          <InputLabel id="software-select-label">
                            Software
                          </InputLabel>

                          <Select
                            labelId="software-select-label"
                            id="software-select"
                            label="Software"
                            onChange={(event) =>
                              setSelectedSoftware(event.target.value)
                            }
                            value={selectedSoftware}
                          >
                            {softwareList
                              .filter(filterOtaList)
                              .map((software) => {
                                return (
                                  <MenuItem
                                    key={software.id}
                                    value={software.id}
                                  >
                                    {`${software.title} (${software.tag})`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </>
                    </Box>
                    <Box sx={{ width: "100%", my: "25px", mb: "50px" }}>
                      <Button
                        sx={{ width: 200, p: 1, mx: 2, float: "right" }}
                        variant="outlined"
                        onClick={() => setShowWarningDialog(true)}
                      >
                        Run
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </>
          <DialogComponent
            dialogTitle="Warning"
            dialogMessage={"Are you sure you want to update?"}
            closable
            showDialog={{
              get: showWarningDialog,
              set: setShowWarningDialog,
            }}
            confirmDialogCallback={onDialogConfirm}
          />
        </>
      )}
      <DialogComponent
        dialogTitle="Confirm"
        dialogMessage={dialogMessage}
        closable
        showDialog={{
          get: showConfirmDialog,
          set: setShowConfirmDialog,
        }}
        confirmDialogCallback={update}
      />
    </>
  );
}

export default FirmwareUpdate;
