import {
  ResetVariablesDto,
  SelectiveResetVariablesDto,
  DataDto,
} from "../Models";
import { AuthService } from "./AuthService";
import { WarrantyExpirationDto } from "../Models/WarrantyExpirationDto";
import {
  UserControllerUrl,
  NetworkControllerUrl,
  DeviceControllerUrl,
  apiURL,
} from ".";
import ServiceResponseDto from "../Models/ServiceResponseDto";

export class AttributesService {
  AuthService: AuthService = new AuthService(apiURL.backofficeApiUrl);

  async DeleteAttributes(request: ResetVariablesDto) {
    if (request.Ids.length > 0) {
      //selective
      var url = `${this.GetControllerUrl(
        request.AssetType.Asset
      )}/DeleteAttributes?keys=${request.Keys}`;
      await this.AuthService.PostAsync(
        url,
        new SelectiveResetVariablesDto(request.Ids)
      );
    } else {
      //massive
      url = `${this.GetControllerUrl(
        request.AssetType.Asset
      )}/DeleteAttributesMassive?keys=${request.Keys}`;
      await this.AuthService.GetAsync<string[]>(url);
    }
  }

  async GetData(type: string) {
    var url = `${this.GetControllerUrl(type)}/GetData`;
    if (type !== "") {
      url = `${url}?assetType=${type}`;
    }
    var res = await this.AuthService.GetAsync<ServiceResponseDto<DataDto[]>>(
      url
    );
    return res;
  }

  GetControllerUrl(assetType: string): string {
    if (assetType === "AppUser") {
      return UserControllerUrl;
    }

    if (assetType === "Network") {
      return NetworkControllerUrl;
    }
    return DeviceControllerUrl;
  }

  async AddExpirations(dtos: WarrantyExpirationDto[]) {
    var url = `${DeviceControllerUrl}/AddExpiration`;
    return await this.AuthService.PostAsync(url, dtos);
  }

  async EnableLastAttributeHistory(dtos: string[]) {
    var url = `${DeviceControllerUrl}/EnableLastAttributeHistory`;
    return await this.AuthService.PostAsync(url, dtos);
  }
}
