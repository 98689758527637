/** @format */

import dayjs from "dayjs";
import {
  IOta,
  FwsDto,
  NetworkDto,
  WarrantyExpirationDto,
  UserDto,
  NetworkMemberRole,
  NetworkMemberDto,
  DeviceDto,
} from "../../Models";
import FailedFirmwareUpdateDto from "../../Models/FailedFirmwareUpdateDto";
import NetworksInMaintenanceDto from "../../Models/NetworksInMaintenanceDto";
import { RfidDto } from "../../Models/RfidDto";
import { DeviceOtaDto } from "../../Models/DeviceOtaDto";
import CPODto from "../../Models/CPODto";

export interface Data {
  firstElement: string;
  elements?: string[];
  id: string;
}

export function createData(
  firstElement: string,
  elements: string[],
  id: string
): Data {
  return {
    firstElement,
    elements,
    id,
  };
}

export function createNetworkData(firstElement: string, id: string): Data {
  return { firstElement, id };
}
export function createRfidData(
  firstElement: string,
  associatedUser: string,
  id: string
): Data {
  associatedUser =
    associatedUser === null ||
    associatedUser === undefined ||
    associatedUser === ""
      ? "Card anonima"
      : associatedUser;
  const elements: string[] = [id, associatedUser];
  return { firstElement, id, elements };
}
export function timestampToDate(timestamp: string): string {
  var date = new Date(Number(timestamp) * 1000);
  return (
    date.toLocaleDateString() + " " + date.getHours() + ":" + date.getMinutes()
  );
}

export function createNetworkInMaintenanceData(
  dto: NetworksInMaintenanceDto
): Data {
  return createData(
    dto.name,
    [dto.userEmail, dto.networkId, timestampToDate(dto.createDate)],
    `${dto.id}`
  );
}

export function createOtaData(dto: IOta): Data {
  return createData(
    dto.deviceProfile,
    [dto.versionEsp, dto.versionStm],
    `${dto.deviceProfile}${dto.versionEsp}${dto.versionStm}`
  );
}

export function createDataFromOblivionRequestsDto(dto: UserDto): Data {
  var data = createData(dto.email, [dto.name, dto.surname], dto.id);
  return data;
}

export function createDeviceOtaDto(dto: DeviceOtaDto): Data {
  var data = createData(
    dto.deviceProfileName,
    [dto.versionStm, dto.versionEsp, dto.deviceCount],
    `${dto.deviceProfileName}${dto.versionStm}${dto.versionEsp}`
  );
  return data;
}

export function createExpirationData(dto: WarrantyExpirationDto): Data {
  var data = createData(
    dto.deviceName,
    [dto.warrantyExpiration],
    dto.deviceName
  );
  return data;
}
export function createDeviceData(deviceName: string): Data {
  return { firstElement: deviceName, id: deviceName };
}
export function createProvisionData(deviceName: string): Data {
  return { firstElement: deviceName, id: deviceName };
}

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

export const UserOblivionHeadCells: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    numeric: true,
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Cognome",
  },
];

export const OtaHeadCells: readonly HeadCell[] = [
  {
    id: "device",
    numeric: false,
    disablePadding: true,
    label: "Device Profile",
  },
  {
    id: "esp",
    numeric: true,
    disablePadding: false,
    label: "Version Esp",
  },
  {
    id: "stm",
    numeric: true,
    disablePadding: false,
    label: "Version Stm",
  },
];

export const NetworksHeadCells: readonly HeadCell[] = [
  {
    id: "network",
    numeric: false,
    disablePadding: true,
    label: "Network Name",
  },
];

export const RfidsHeadCells: readonly HeadCell[] = [
  {
    id: "rfidName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "rfidSerial",
    numeric: true,
    disablePadding: false,
    label: "Serial Number",
  },
  {
    id: "rfidAssociatedUser",
    numeric: true,
    disablePadding: false,
    label: "Associated User",
  },
];

export const NetworksInMaintenanceHeadCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Network Name",
  },
  {
    id: "userEmail",
    numeric: false,
    disablePadding: true,
    label: "User Email",
  },
  {
    id: "networkId",
    numeric: false,
    disablePadding: true,
    label: "Network Id",
  },
  {
    id: "createDate",
    numeric: false,
    disablePadding: true,
    label: "Created",
  },
];

export function createDataFromNetworks(dto: NetworkDto): Data {
  var data = createNetworkData(dto.name, dto.id);
  return data;
}
export function createDataFromRfids(dto: RfidDto): Data {
  var data = createRfidData(dto.name, dto.associatedUser, dto.serialNumber);
  return data;
}
export function createDataFromUsers(dto: UserDto): Data {
  var data = createData(dto.name, [dto.surname, dto.email], dto.id);
  return data;
}
export function createDataFromNetworkUsers(dto: UserDto): Data {
  var data = createData(dto.email, [dto.name, dto.surname], dto.email);
  return data;
}
export function createDataFromNetworkMembers(dto: NetworkMemberDto): Data {
  var data = createData(
    dto.email,
    [dto.name, dto.surname, NetworkMemberRole[dto.role]],
    dto.role
  );
  return data;
}
export function createDataFromCPO(dto: CPODto): Data {
  var data = createData(
    dto.name,
    [dto.displayName, dto.visible ? "true" : "false"],
    dto.name
  );
  return data;
}
export function createDataFromNetworkAdmins(dto: UserDto): Data {
  var data = createData(dto.email, [dto.name, dto.surname], dto.email);
  return data;
}
export function createDataFromNetworkDevices(dto: DeviceDto): Data {
  var data = createData(dto.name, [], dto.id);
  return data;
}
export function createDataFromManuallyUpdatedWallBoxes(
  serialNumber: string
): Data {
  var data = createData(serialNumber, [], serialNumber);
  return data;
}
export function createDataFromUnsupportedCPOWallBoxes(
  serialNumber: string
): Data {
  return createData(serialNumber, [], serialNumber);
}
export function createDataFromFailedFirmwareUpdateDto(
  dto: FailedFirmwareUpdateDto
): Data {
  var data = createData(
    dto.deviceName,
    [
      dto.currentFirmwareVersion === "" ? "N/A" : dto.currentFirmwareVersion,
      dto.currentSoftwareVersion === "" ? "N/A" : dto.currentSoftwareVersion,
      dto.requestedFirmwareVersion,
      dto.requestedSoftwareVersion,
      dto.isDeviceActive ? "Active" : "Not Active",
    ],
    dto.deviceName
  );
  return data;
}
export const allUsersHeadCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "surname",
    numeric: true,
    disablePadding: false,
    label: "Surname",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "User Email",
  },
];

export const NetworkAdminsHeadCell: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "surname",
    numeric: true,
    disablePadding: false,
    label: "Surname",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Remove",
  },
];

export const FwsHeadCells: readonly HeadCell[] = [
  {
    id: "deviceProfileName",
    numeric: false,
    disablePadding: true,
    label: "Device Profile",
  },
  {
    id: "versionStm",
    numeric: true,
    disablePadding: false,
    label: "Firmware",
  },
  {
    id: "versionEsp",
    numeric: true,
    disablePadding: false,
    label: "Software",
  },
  {
    id: "deviceCount",
    numeric: true,
    disablePadding: false,
    label: "Device Count",
  },
];

export const ExpirationHeadCells: readonly HeadCell[] = [
  {
    id: "device",
    numeric: false,
    disablePadding: true,
    label: "Device Name",
  },
  {
    id: "Expiration",
    numeric: true,
    disablePadding: false,
    label: "Expiration Date",
  },
];
export const DeviceFirmwareHeadCell: readonly HeadCell[] = [
  {
    id: "device",
    numeric: false,
    disablePadding: true,
    label: "Device Name",
  },
];
export const NetworkUsersHeadCell: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "surname",
    numeric: true,
    disablePadding: false,
    label: "Surname",
  },
];
export const NetworkMembersHeadCell: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "surname",
    numeric: true,
    disablePadding: false,
    label: "Surname",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Change Role",
  },
];
export const NetworkMembersHeadCellNoEdit: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "surname",
    numeric: true,
    disablePadding: false,
    label: "Surname",
  },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
];
export const NetworkDevicesHeadCell: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Remove",
  },
];
export const CustomerProvisionHeadCell: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Device Name",
  },
];
export const FailedFirmwareHeadCells: readonly HeadCell[] = [
  {
    id: "deviceName",
    numeric: false,
    disablePadding: true,
    label: "Serial Number",
  },
  {
    id: "currentFw",
    numeric: true,
    disablePadding: false,
    label: "Current Firmware Version",
  },
  {
    id: "currentSw",
    numeric: true,
    disablePadding: false,
    label: "Current Software Version",
  },
  {
    id: "requestedFw",
    numeric: true,
    disablePadding: false,
    label: "Requested Firmware Version",
  },
  {
    id: "requestedSw",
    numeric: true,
    disablePadding: false,
    label: "Requested Software Version",
  },
  {
    id: "isActive",
    numeric: true,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Retry Update",
  },
];
export const CPOHeadCell: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "displayName",
    numeric: true,
    disablePadding: false,
    label: "Display Name",
  },
  {
    id: "visible",
    numeric: true,
    disablePadding: false,
    label: "Visible",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];
export const UnsupportedCPODevicesHeadCell: readonly HeadCell[] = [
  {
    id: "serialNumber",
    numeric: false,
    disablePadding: true,
    label: "Serial Number",
  },
];
export const ManuallyUpdatedWallBoxHeadCells: readonly HeadCell[] = [
  {
    id: "serialNumber",
    numeric: false,
    disablePadding: true,
    label: "Serial Number",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Return to standard update",
  },
];
export const errorBlackList: readonly number[] = [34];

export function IsErrInBlackList(error: number) {
  return errorBlackList.includes(error);
}

export function unixTimestampToDateTime(unixTimestamp: number): string {
  const date = dayjs(unixTimestamp);
  return date.format("DD/MM/YYYY HH:mm:ss");
}
