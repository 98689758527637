import { Box } from "@mui/material";
import { RadialChartOptions } from "./RadialBarChart";
import ReactApexcharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const PowerBarChart = (
  props: RadialChartOptions & { isWallBoxThreePhase: boolean }
) => {
  const labels: string[] = Array.isArray(props.labels)
    ? props.labels
    : [props.labels];
  const valuesToArray: number[] = Array.isArray(props.series)
    ? props.series
    : [props.series];

  function getValuePercentage(value: number, isWallBoxThreePhase: boolean) {
    if (isWallBoxThreePhase) {
      return (value / 22) * 100;
    } else {
      return (value / 7.4) * 100;
    }
  }
  function getOriginalValue(value: number, fisWallBoxThreePhase: boolean) {
    if (props.isWallBoxThreePhase) {
      return (value / 100) * 22;
    } else {
      return (value / 100) * 7.4;
    }
  }

  const series = valuesToArray.map((value) =>
    getValuePercentage(value, props.isWallBoxThreePhase)
  );

  const options: ApexOptions = {
    colors: props.barColors ?? ["#F2B200"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 3,
          background: "#00785c",
          size: "70%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Arial",
            fontWeight: 400,
            show: true,
          },
          value: {
            color: "#fff",
            fontSize: "20px",
            fontFamily: "Arial",
            fontWeight: 400,
            show: true,
            formatter: function (val: number) {
              return `${valuesToArray[0]}KW`;
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#f5e2ae"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    grid: {
      padding: {
        top: -23,
        bottom: -23,
        right: 20,
        left: 20,
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    stroke: {
      lineCap: "round",
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 1500,
        options: {
          plotOptions: {},
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            },
          },
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
      <ReactApexcharts
        options={options}
        series={series}
        type="radialBar"
        height={300}
        width={"100%"}
      />
    </Box>
  );
};
export default PowerBarChart;
