import { ConfigurationControllerUrl, apiURL } from ".";
import { AppVersionDto, PopupInfoDto, ServiceResponseDto } from "../Models";
import { AuthService } from "./AuthService";

export class ConfigurationService {
  authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
  async SetPopupInfo(popupInfo: PopupInfoDto) {
    var url = `${ConfigurationControllerUrl}/AddPopupInfo`;
    await this.authService.PostAsync(url, popupInfo);
  }
  async GetPopupInfo(): Promise<PopupInfoDto> {
    var url = `${ConfigurationControllerUrl}/GetPopupInfo`;
    var res: ServiceResponseDto<PopupInfoDto> =
      await this.authService.GetAsync<PopupInfoDto>(url);
    return res;
  }
  async SetAppVersion(appVersion: AppVersionDto) {
    var url = `${ConfigurationControllerUrl}/SetAppVersion`;
    await this.authService.PostAsync(url, appVersion);
  }
  async GetAppVersion(): Promise<AppVersionDto> {
    var url = `${ConfigurationControllerUrl}/GetAppVersion`;
    var res: ServiceResponseDto<AppVersionDto> =
      await this.authService.GetAsync<AppVersionDto>(url);
    return res;
  }

  async GetDazeSupportedLanguages(): Promise<string[]> {
    var url = `${ConfigurationControllerUrl}/GetSupportedLanguages`;
    var res: ServiceResponseDto<string[]> =
      await this.authService.GetAsync<string[]>(url);
    return res;
  }
}
