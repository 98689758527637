import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import { Data } from "ws";
import { HeadCell } from "./utils";

interface EnhancedTableProps {
    numSelected: number;
    selectable: boolean;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    headCells : readonly HeadCell[],
  }
  
  export function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount, onRequestSort } =
      props;
  
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {props.selectable && 
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />}
          </TableCell>
          {props.headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
                {headCell.label}
                
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  