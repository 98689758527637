import { IconButton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import { alpha } from "@mui/system";
import CachedIcon from '@mui/icons-material/Cached';

interface EnhancedTableToolbarProps {
    numSelected: number;
    deselect : () => void;
    selectionAction: () => void;
    selectionIcon : JSX.Element | null;
    tabTitle: string
    reload : (() => Promise<void>) | (() => void)  | null;
  }
  
  export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.tabTitle}
          </Typography>
        )}
        { props.reload !== null && (
          <Tooltip title="Reload">
            <IconButton onClick={async () =>{
                if(props.reload){
                  props.deselect();
                  await props.reload();
                }
              }}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        ) }
        {props.selectionIcon !== null && (
          <Tooltip title="Delete">
            <IconButton onClick={()=>{props.selectionAction()}} disabled={numSelected === 0}>
              {props.selectionIcon}
            </IconButton>
          </Tooltip>
        ) }
      </Toolbar>
    );
  }