/** @format */

import React, { useEffect, useState } from "react";
import { IPageProps, WarrantyExpirationDto } from "../../../Models";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button/Button";
import {
  Data,
  ExpirationHeadCells,
  createExpirationData,
} from "../../../Components/TableComponent/utils";
import { TableComponent } from "../../../Components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { AttributesService } from "../../../Services";

function WarrantyExpiration(props: IPageProps) {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [array, setArray] = useState<WarrantyExpirationDto[]>([]);
  const [rows, setRows] = useState<Data[]>([]);

  const service = new AttributesService();

  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const tmp: WarrantyExpirationDto[] = csvRows.map((i) => {
      const values = i.split(";");
      const obj = csvHeader.reduce((object: any, header, index) => {
        return new WarrantyExpirationDto(values);
      }, {});
      return obj;
    });
    setArray(tmp.filter((obj) => obj.deviceName !== ""));
  };

  const clearFileFields = () => {
    setFile(undefined);
    setFileName("");
  };

  useEffect(() => {
    if (!array) {
      return;
    }
    if (array.length === 0) {
      clearFileFields();
    }
    setRows(array.map((a) => createExpirationData(a)));
  }, [array]);

  const ReadFile = () => {
    if (file) {
      props.setIsLoading(true);
      fileReader.onload = function (event) {
        if (
          event !== null &&
          event.target !== null &&
          event.target.result !== null
        ) {
          const text: string = event.target.result.toString();
          csvFileToArray(text);
        }
      };

      fileReader.readAsText(file);
      props.setIsLoading(false);
    }
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    ReadFile();
  };

  const click = async () => {
    if (array.length === 0) {
      props.handleAlertShow("warning", "No device(s) selected");
      return;
    }
    try {
      props.setIsLoading(true);
      await service.AddExpirations(array);
      setRows([]);
      setArray([]);
      clearFileFields();
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const removeExpiration = (selected: readonly string[]) => {
    const filtered = array.filter(
      (item) => !selected.includes(item.deviceName)
    );
    setArray(filtered);
  };
  return (
    <>
      {props.isAdmin && (
        <>
          <Button
            onClick={handleOnChange}
            sx={{
              width: "180px",
              height: "60px",
              fontSize: "15px",
              borderRadius: "10px",
            }}
            component="label"
          >
            <input
              type="file"
              accept=".csv"
              onChange={handleOnChange}
              onClick={(e: any) => (e.target.value = null)}
              hidden
            />
            CHOOSE FILE
            <AddCircleRoundedIcon
              fontSize="medium"
              sx={{ ml: "5px" }}
            ></AddCircleRoundedIcon>
          </Button>
          <label>{fileName !== "" && fileName}</label>
          <Tooltip title="Upload File">
            <Button onClick={handleOnSubmit} sx={{ ml: "10px" }}>
              <CloudUploadIcon fontSize="medium" />
            </Button>
          </Tooltip>
          {rows && rows.length > 0 && (
            <>
              <TableComponent
                rows={rows}
                headCells={ExpirationHeadCells}
                selectionIcon={<RemoveCircleOutlineIcon />}
                selectionAction={removeExpiration}
                rowAction={null}
                rowActionIcon={null}
                tabTitle={"Uploaded file"}
                reload={ReadFile}
              />
              <Button
                sx={{ width: 200, p: 1, m: 2, float: "right" }}
                onClick={click}
                variant="outlined"
              >
                Run
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default WarrantyExpiration;
