/** @format */

import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import { IPageProps } from "../../Models";
import { ButtonGroup } from "@mui/material";
import React from "react";
import ResetVariables from "./ResetVariables/ResetVariables";
import LastAttributesHistory from "./LastAttributesHistory/LastAttributesHistory";
import WarrantyExpiration from "./WarrantyExpiration/WarrantyExpiration";

function AttributesManagement(props: IPageProps) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant={selectedTab === 0 ? "contained" : "outlined"}
            onClick={() => setSelectedTab(0)}
          >
            Reset
          </Button>
          <Button
            variant={selectedTab === 1 ? "contained" : "outlined"}
            onClick={() => setSelectedTab(1)}
          >
            "Last" attributes history
          </Button>
          <Button
            variant={selectedTab === 2 ? "contained" : "outlined"}
            onClick={() => setSelectedTab(2)}
          >
            Warranty Expiration
          </Button>
        </ButtonGroup>
        {selectedTab === 0 ? (
          <Box marginTop={5}>
            <ResetVariables {...props} />
          </Box>
        ) : selectedTab === 1 ? (
          <Box marginTop={5}>
            <LastAttributesHistory {...props} />
          </Box>
        ) : (
          <Box marginTop={5}>
            <WarrantyExpiration {...props} />
          </Box>
        )}
      </Box>
    </>
  );
}

export default AttributesManagement;
