import { DeprecateOtaRequest } from "../Models/DeprecateOtaRequest";
import { AuthService } from "./AuthService";
import {
  IOta,
  FwsDto,
  DeviceProfileDto,
  GetNumSupportedOtaDevicesDto,
  FirmwareUpdateSearchMode,
  FirmwareUpdateMode,
  ServiceResponseDto,
} from "../Models";
import { DeprecateOtaToolControllerUrl, apiURL } from ".";
import FailedFirmwareUpdateDto from "../Models/FailedFirmwareUpdateDto";
import UpdateDeviceRequestDto from "../Models/UpdateDeviceRequestDto";
import OtaUpdateResponseDto from "../Models/OtaUpdateResponseDto";
import OtaUpdateStatusDto from "../Models/OtaUpdateStatusDto";
import { DeviceOtaDto } from "../Models/DeviceOtaDto";

export class OtaService {
  authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
  async Send(request: DeprecateOtaRequest) {
    const versionNumberRegex: RegExp = /^\d+\.\d+\.\d+$/;

    if (request.otas.length > 0) {
      for (const item of request.otas) {
        if (!item.deviceProfile) {
          throw new Error("Empty device profile " + item.deviceProfile);
        }
        if (!versionNumberRegex.test(item.versionEsp)) {
          throw new Error("Invalid version esp: " + item.versionEsp);
        }
        if (!versionNumberRegex.test(item.versionStm)) {
          throw new Error("Invalid version stm: " + item.versionStm);
        }
      }
    } else {
      throw new Error("Lista Ota vuota");
    }
    await this.authService.PostAsync(
      `${DeprecateOtaToolControllerUrl}/Deprecate`,
      request
    );
  }

  async GetDeviceProfileNames(): Promise<string[]> {
    var url = `${DeprecateOtaToolControllerUrl}/GetDeviceProfileNames`;
    var res: ServiceResponseDto<string[]> = await this.authService.GetAsync<
      string[]
    >(url);
    return res;
  }
  async GetDeviceProfiles(): Promise<ServiceResponseDto<DeviceProfileDto[]>> {
    var url = `${DeprecateOtaToolControllerUrl}/GetDeviceProfiles`;
    var res: ServiceResponseDto<DeviceProfileDto[]> =
      await this.authService.GetAsync<DeviceProfileDto[]>(url);
    return res;
  }

  async GetFws(): Promise<ServiceResponseDto<FwsDto[]>> {
    var url = `${DeprecateOtaToolControllerUrl}/GetFws`;
    var res: ServiceResponseDto<FwsDto[]> = await this.authService.GetAsync<
      FwsDto[]
    >(url);
    return res;
  }

  async GetDeviceOtas(): Promise<ServiceResponseDto<DeviceOtaDto[]>> {
    var url = `${DeprecateOtaToolControllerUrl}/GetDevicesOta`;
    var res: ServiceResponseDto<DeviceOtaDto[]> =
      await this.authService.GetAsync<DeviceOtaDto[]>(url);
    return res;
  }

  async GetOtaDeprecated(): Promise<IOta[]> {
    var url = `${DeprecateOtaToolControllerUrl}/GetOtaDeprecated`;
    var res: ServiceResponseDto<IOta[]> = await this.authService.GetAsync<
      IOta[]
    >(url);
    return res;
  }

  async DeleteOtaDeprecated(request: DeprecateOtaRequest): Promise<void> {
    await this.authService.PostAsync(
      `${DeprecateOtaToolControllerUrl}/RemoveOtaDeprecated`,
      request
    );
  }
  async GetNumUpdatableDevices(
    updateRequest: UpdateDeviceRequestDto
  ): Promise<ServiceResponseDto<GetNumSupportedOtaDevicesDto>> {
    return await this.authService.PostAsyncAndReturn<ServiceResponseDto<GetNumSupportedOtaDevicesDto>>(
        `${DeprecateOtaToolControllerUrl}/GetNumUpdatableDevices`,updateRequest
    );
  }
  async GetFailedUpdateDevices(
    searchBy: string,
    searchMode: FirmwareUpdateSearchMode
  ): Promise<ServiceResponseDto<FailedFirmwareUpdateDto[]>> {
    var res: ServiceResponseDto<FailedFirmwareUpdateDto[]> =
      await this.authService.GetAsync<FailedFirmwareUpdateDto[]>(
        `${DeprecateOtaToolControllerUrl}/GetFailedUpdateDevices?searchBy=${searchBy}&searchMode=${searchMode}`
      );
    return res;
  }

  async UpdateDevices(
    updateRequest: UpdateDeviceRequestDto
  ): Promise<ServiceResponseDto<OtaUpdateResponseDto>> {
    return await this.authService.PostAsyncAndReturn<
      ServiceResponseDto<OtaUpdateResponseDto>
    >(`${DeprecateOtaToolControllerUrl}/UpdateDevices`, updateRequest);
  }
  async CheckOtaUpdateStatus(): Promise<
    ServiceResponseDto<OtaUpdateStatusDto>
  > {
    var res: ServiceResponseDto<OtaUpdateStatusDto> =
      await this.authService.GetAsync<OtaUpdateStatusDto>(
        `${DeprecateOtaToolControllerUrl}/GetOtaUpdateStatus`
      );
    return res;
  }
}
