/** @format */

import FormControl from "@mui/material/FormControl/FormControl";
import IconButton from "@mui/material/IconButton/IconButton";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select/Select";
import React, { useEffect } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import {
  AttributeAutoCompleteSelect,
  AutoCompleteSelect,
} from "../../../Components";
import {
  IPageProps,
  DataDto,
  ResetVariablesDto,
  getAssetTypeDtos,
} from "../../../Models";
import { AttributesService } from "../../../Services";

function ResetVariables(props: IPageProps) {
  const [variables, setVariables] = useState<string[]>([]);
  const assetTypes = getAssetTypeDtos();
  const [asset, setAsset] = useState<number>(0);
  const [massiveReset, setMassiveReset] = useState<boolean>(true);
  const [ids, setIds] = useState<string[]>([]);
  const [data, setData] = useState<DataDto[]>([]);
  const [users, setUsers] = useState<DataDto[]>([]);
  const [nets, setNets] = useState<DataDto[]>([]);
  const [devs, setDevs] = useState<DataDto[]>([]);
  //const [attributes, setAttributes] = useState<string[]>([]);

  const service = new AttributesService();

  const getData = async (type: string) => {
    props.setIsLoading(true);
    var d: DataDto[] = [];
    try {
      const response = await service.GetData(type);
      d = response.data;
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
    return d;
  };

  const add = () => {
    setVariables([...variables, ""]);
  };

  const remove = (index: number) => {
    const newVariables = variables.filter((_, i) => i !== index);
    setVariables(newVariables);
  };

  const addId = () => {
    setIds([...ids, ""]);
  };
  const removeId = (index: number) => {
    const newIds = ids.filter((_, i) => i !== index);
    //If we have removed all the assets clear the attributes...
    if (newIds.length === 0) {
      setVariables([]);
    }
    setIds(newIds);
  };
  const updateVariables = (indexToSet: number, newValue: string) => {
    setVariables((current: string[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          return newValue;
        }
        return obj;
      })
    );
  };
  const updateIds = (indexToSet: number, newValue: string) => {
    setIds((current: string[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          return newValue;
        }
        return obj;
      })
    );
  };

  const confirmCallback = async () => {
    props.setIsLoading(true);
    const stringVariables: string = variables.toString();
    var dto = new ResetVariablesDto(stringVariables, assetTypes[asset], ids);
    try {
      await service.DeleteAttributes(dto);
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const click = async () => {
    await confirmCallback();
  };

  // useEffect(() => {
  //   setIds([]);
  //   if (!massiveReset) {
  //     loadData(asset);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [asset, massiveReset]);

  useEffect(() => {
    if (!massiveReset) {
      loadData(asset);
    } else {
      setIds([]);
    }
  }, [asset, massiveReset]);

  const loadData = async (index: number) => {
    var d: DataDto[] = [];
    setVariables([]);
    if (index === 0) {
      if (devs.length === 0) {
        d = await getData(assetTypes[index].Asset);
        setData(d);
        setDevs(d);
        return;
      }
      setData(devs);
    } else if (index === 1) {
      if (users.length === 0) {
        d = await getData(assetTypes[index].Asset);
        setData(d);
        setUsers(d);
        return;
      }
      setData(users);
    } else if (index === 2) {
      if (nets.length === 0) {
        d = await getData(assetTypes[index].Asset);
        setNets(d);
        setData(d);
        return;
      }
      setData(nets);
    }
  };

  const anyValidId = (): boolean => {
    return massiveReset || (ids.length > 0 && ids.some((val) => val !== ""));
  };

  return (
    <div>
      {props.isAdmin && (
        <FormGroup>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl sx={{ width: "48%" }} size="small">
              <InputLabel id="demo-simple-select-label">Entity</InputLabel>
              <Select
                label="Session type"
                sx={{ padding: "2%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(event) => setAsset(Number(event.target.value))}
                value={asset}
              >
                {assetTypes.map((assetType, index) => (
                  <MenuItem key={index} value={index}>
                    {assetType.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  onChange={(event) => setMassiveReset(event.target.checked)}
                  checked={massiveReset}
                />
              }
              label="Massive reset"
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "45%", marginTop: "2.5rem" }}>
              {anyValidId() && (
                <IconButton
                  onClick={add}
                  sx={{
                    width: "180px",
                    height: "60px",
                    fontSize: "15px",
                    borderRadius: "10px",
                  }}
                  color="primary"
                >
                  <label
                    style={{
                      marginRight: "10px",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Add Attribute
                  </label>
                  <AddCircleRoundedIcon fontSize="large"></AddCircleRoundedIcon>
                </IconButton>
              )}
              <div>
                {variables?.map((variable, index) => (
                  <div key={index} style={{ marginTop: "1rem" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!massiveReset && ids.length === 1 ? (
                        <AttributeAutoCompleteSelect
                          setParam={(val: string) =>
                            updateVariables(index, val)
                          }
                          handleAlertShow={props.handleAlertShow}
                          disabled={false}
                          label={"Attribute"}
                          styleToRender={{
                            width: "70%",
                            marginRight: "30px",
                          }}
                          setIsLoading={props.setIsLoading}
                          assetType={assetTypes[asset].Asset}
                          assetId={ids[0]}
                          initialValue={variable}
                          paramKey="name"
                        />
                      ) : (
                        <TextField
                          id="outlined-read-only-input"
                          label="Attribute"
                          sx={{ width: "70%", marginRight: "30px" }}
                          value={variable}
                          onChange={(newVariable) => {
                            updateVariables(index, newVariable.target.value);
                          }}
                        />
                      )}
                      <IconButton
                        aria-label="delete"
                        sx={{ border: "1px solid #223AA3" }}
                        color="primary"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
            </div>

            {!massiveReset && (
              <div style={{ width: "45%", marginTop: "2.5rem" }}>
                <IconButton
                  onClick={addId}
                  sx={{
                    width: "180px",
                    height: "60px",
                    fontSize: "15px",
                    borderRadius: "10px",
                  }}
                  color="primary"
                >
                  <label
                    style={{
                      marginRight: "10px",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Add {assetTypes[asset].Name.toUpperCase()}
                  </label>
                  <AddCircleRoundedIcon fontSize="large"></AddCircleRoundedIcon>
                </IconButton>

                <div>
                  {data &&
                    data.length > 0 &&
                    ids?.map((idValue, index) => (
                      <div key={index} style={{ marginTop: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AutoCompleteSelect
                            setParam={(val: string) => updateIds(index, val)}
                            handleAlertShow={props.handleAlertShow}
                            disabled={false}
                            label={assetTypes[asset].Name}
                            styleToRender={{
                              width: "70%",
                              marginRight: "30px",
                            }}
                            data={data}
                          />
                          <IconButton
                            aria-label="delete"
                            sx={{ border: "1px solid #223AA3" }}
                            color="primary"
                            onClick={() => removeId(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                        <br />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          <Box sx={{ p: "1rem" }}>
            <Button
              sx={{ width: 200, p: 1, mr: 2, float: "right" }}
              onClick={click}
              variant="outlined"
            >
              Run
            </Button>
          </Box>
        </FormGroup>
      )}
    </div>
  );
}

export default ResetVariables;
