import { TelemetriesGenerationRequest } from "../Models";
import { AuthService } from "./AuthService";
import { DeviceControllerUrl, apiURL } from ".";

export class TelemetriesGenerationService {
  AuthService: AuthService = new AuthService(apiURL.backofficeApiUrl);

  async Send(request: TelemetriesGenerationRequest) {
    if (!request.Device || request.Device.length === 0) {
      throw new Error("Device required");
    }
    if (Number.isNaN(parseFloat(request.ChargeTime))) {
      throw new Error("ChargeTime is not a number");
    }
    if (parseFloat(request.ChargeTime) < 7200) {
      throw new Error("ChargeTime has to be at least 7200s");
    }
    if (Number.isNaN(parseFloat(request.AveragePow))) {
      throw new Error("AveragePower is not a number");
    }
    if (Number.isNaN(parseFloat(request.TotEnergy))) {
      throw new Error("TotEnergy is not a number");
    }
    if (Number.isNaN(parseFloat(request.RechargesNumber))) {
      throw new Error("RechargesNumber is not a number");
    }

    if (
      request.SessionType !== 4 &&
      request.SessionType !== 5 &&
      request.SessionType !== 3
    ) {
      request.Uid = "unknown";
    }
    if (
      request.SessionType === 4 &&
      (!request.Uid || request.Uid.length === 0)
    ) {
      throw new Error("UserUid required");
    }
    if (
      (request.SessionType === 3 || request.SessionType === 5) &&
      (!request.Uid || request.Uid.length === 0)
    ) {
      throw new Error("RFID required");
    }

    await this.AuthService.PostAsync(
      `${DeviceControllerUrl}/GenerateTelemetry`,
      request
    );
  }
}
