import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { Dispatch, SetStateAction, useState } from "react";
import { NetworkDto } from "../../../Models";
import { CircularProgress } from "@mui/material";

interface NetworkAutoCompleteSelectProps {
  setParam: React.Dispatch<React.SetStateAction<string>>;
  data: {
    get: NetworkDto[];
    set: Dispatch<SetStateAction<NetworkDto[]>>;
  };
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
  resetKey: any;
}

export default function NetworkAutoCompleteSelect(
  props: NetworkAutoCompleteSelectProps
) {
  const [value, setValue] = useState<string>("");
  const [loading, setIsLoading] = useState(false);

  const setNetworkParam = (val: NetworkDto | null) => {
    if (!val) return;
    if (props.paramKey === "name") {
      props.setParam(val.name);
    } else if (props.paramKey === "networkUid") {
      props.setParam(val.uid);
    } else {
      props.setParam(val.id);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      getOptionLabel={(dvc) => {
        return dvc.uid + " " + dvc.name;
      }}
      id="combo-box-demo"
      options={props.data.get}
      onChange={(event: any, newValue: NetworkDto | null) => {
        setNetworkParam(newValue);
      }}
      key={props.resetKey}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
