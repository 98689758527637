/** @format */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  SelectableItemDto,
  DataDto,
  IPageProps,
  ResetVariablesDto,
  getDeviceAssetTypeDto,
} from "../../../Models";
import React, { useEffect } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { AttributesService } from "../../../Services";
import { AutoCompleteSelect } from "../../../Components";
import DeleteIcon from "@mui/icons-material/Delete";

function LastAttributesHistory(props: IPageProps) {
  const [wbs, setWbs] = React.useState<SelectableItemDto[]>([]);
  const [data, setData] = React.useState<DataDto[]>([]);
  const service = new AttributesService();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    if (data.length === 0) {
      var d = await getData("DEVICE");
      setData(d);
      return;
    }
    setData(data);
  };

  const confirmCallback = async () => {
    props.setIsLoading(true);
    try {
      if (wbs.length === 0) throw new Error("Nessuna wallbox selezionata");
      if (wbs.some((x) => x.item === ""))
        throw new Error("Il campo wallbox è obbligatorio");
      if (wbs.some((x, i) => wbs.slice(i + 1).some((y) => x.item === y.item))) {
        throw new Error("Trovati due elementi con lo stesso valore");
      }
      var wbToEnable: string[] = [];
      var wbToDisable: string[] = [];

      for (const item of wbs) {
        if (item.isSelected) wbToEnable.push(item.item);
        else wbToDisable.push(item.item);
      }

      if (wbToDisable.length > 0) {
        var dtoToDisable = new ResetVariablesDto(
          "clientAttributesHistoryEnabled",
          getDeviceAssetTypeDto(),
          wbToDisable
        );
        await service.DeleteAttributes(dtoToDisable);
      }
      if (wbToEnable.length > 0)
        await service.EnableLastAttributeHistory(wbToEnable);

      props.handleAlertShow("success", "L'operazione è andata a buon fine");
      setWbs([]);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const run = async () => {
    await confirmCallback();
  };

  const getData = async (type: string) => {
    props.setIsLoading(true);
    var d: DataDto[] = [];
    try {
      const response = await service.GetData(type);
      d = response.data;
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
    return d;
  };

  const updateWbs = (indexToSet: number, newValue: string) => {
    setWbs((current: SelectableItemDto[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          return new SelectableItemDto(newValue, false);
        }
        return obj;
      })
    );
  };

  const removeWb = (index: number) => {
    const newWbs = wbs.filter((_, i) => i !== index);
    setWbs(newWbs);
  };

  const addWb = () => {
    setWbs([...wbs, new SelectableItemDto("", false)]);
  };

  const handleRadioChange = (indexToSet: number, value: string) => {
    setWbs((current: SelectableItemDto[]) =>
      current.map((obj, index) => {
        if (index === indexToSet) {
          obj.isSelected = value === "0" ? false : true;
        }
        return obj;
      })
    );
  };

  return (
    <div>
      <Box marginTop={3}>
        <Box marginTop={5}>
          {
            <Box>
              <h3>Choose Wallboxes</h3>
              <IconButton
                onClick={addWb}
                sx={{
                  width: "180px",
                  height: "60px",
                  fontSize: "15px",
                  borderRadius: "10px",
                }}
                color="primary"
              >
                <label
                  style={{
                    marginRight: "10px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Add Wallbox{" "}
                </label>
                <AddCircleRoundedIcon fontSize="large"></AddCircleRoundedIcon>
              </IconButton>
              <div>
                {data &&
                  data.length > 0 &&
                  wbs?.map((item, index) => (
                    <div key={index} style={{ marginTop: "1rem" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AutoCompleteSelect
                          setParam={(val: string) => updateWbs(index, val)}
                          handleAlertShow={props.handleAlertShow}
                          disabled={false}
                          label={"DEVICE"}
                          styleToRender={{
                            width: "50%",
                            marginRight: "50px",
                          }}
                          data={data}
                        />
                        <FormControl>
                          <RadioGroup
                            row
                            onChange={(event) => {
                              handleRadioChange(index, event.target.value);
                            }}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              checked={!item.isSelected}
                              value="0"
                              control={<Radio />}
                              label="Disabled"
                            />
                            <FormControlLabel
                              checked={item.isSelected}
                              value="1"
                              control={<Radio />}
                              label="Enabled"
                            />
                          </RadioGroup>
                        </FormControl>
                        <IconButton
                          aria-label="delete"
                          sx={{ border: "1px solid #223AA3" }}
                          color="primary"
                          onClick={() => removeWb(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <br />
                    </div>
                  ))}
              </div>
              <br />
              <Box sx={{ p: "1rem" }}>
                <Button
                  sx={{ width: 200, p: 1, mr: 2, float: "right" }}
                  onClick={run}
                  variant="outlined"
                >
                  Run
                </Button>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </div>
  );
}

export default LastAttributesHistory;
