import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import { ServiceResponseDto, UserDto } from "../../../Models";
import { NetworkService } from "../../../Services";
import { CircularProgress } from "@mui/material";

interface UserAutoCompleteSelectProps {
  setParam: React.Dispatch<React.SetStateAction<string>>;
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
  resetKey?: any;
}

export default function UserAutoCompleteSelect(
  props: UserAutoCompleteSelectProps
) {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [value, setValue] = useState<string>("");
  const [loading, setIsLoading] = useState(false);
  const service = new NetworkService();

  useEffect(() => {
    if (value.length < 5) {
      setUsers([]);
      return;
    }
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setUser = (val: UserDto | null) => {
    if (!val) return;
    console.log(props.paramKey + " Val: " + val.email);
    if (props.paramKey === "name") {
      props.setParam(val.name);
    } else if (props.paramKey === "email") {
      props.setParam(val.email);
    } else if (props.paramKey === "id") {
      props.setParam(val.id);
    }
    setUsers([val]);
  };

  const loadUsers = async () => {
    setIsLoading(true);
    try {
      var res: ServiceResponseDto<UserDto[]> = await service.GetUsersByName(
        value
      );
      setUsers(res.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      key={props.resetKey}
      disabled={props.disabled}
      disablePortal
      getOptionLabel={(usr) => {
        return usr.email;
      }}
      id="combo-box-demo"
      options={users}
      onChange={(event: any, newValue: UserDto | null) => {
        setUser(newValue);
      }}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
