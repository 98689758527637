import * as React from "react";
import Box from "@mui/material/Box";
import {
  MenuComponent,
  AccountBarComponent,
  PagesComponent,
  DialogComponent,
  FooterComponent,
} from "../../Components";
import { useEffect, useState } from "react";
import { User, useAuth } from "oidc-react";

export default function Home() {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState<User | null | undefined>();
  const [correctUser, setCorrectUser] = useState<boolean>(true);

  const auth = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }
    var roles: string[] = user.profile.role as string[];
    if (
      !roles ||
      roles.length === 0 ||
      (!roles.includes("BackofficeAdmin") && !roles.includes("Backoffice"))
    ) {
      setCorrectUser(false);
      return;
    }
    setCorrectUser(true);
  }, [user]);

  useEffect(() => {
    setUser(auth.userData);
  }, [auth]);
  const logout = async () => {
    await auth.signOutRedirect();
  };

  const getRole: () => string = () => {
    if (!user) {
      return "";
    }
    try {
      var roles: string[] = user.profile.role as string[];
      return roles.includes("BackofficeAdmin") ? "Admin" : "User";
    } catch {
      setCorrectUser(false);
      return "";
    }
  };

  const isAdmin: () => boolean = () => {
    return getRole() === "Admin";
  };

  return (
    <>
      {correctUser && getRole() !== "" && (
        <Box sx={{ width: "100vw" }}>
          <Box
            sx={{
              display: "flex",
              padding: "2%",
              height: "100vh",
              backgroundColor: "#eaeaea",
            }}
          >
            <MenuComponent
              isAdmin={isAdmin()}
              value={{
                get: value,
                set: setValue,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "2%",
                mr: "2%",
                width: "100%",
              }}
            >
              <AccountBarComponent
                account={user?.profile.name}
                email={user?.profile.email}
                role={getRole()}
                logout={logout}
              />
              <PagesComponent isAdmin={isAdmin()} value={value} />
            </Box>
          </Box>
          <FooterComponent />
        </Box>
      )}

      <DialogComponent
        dialogTitle={"Not Authorized"}
        dialogMessage={"You need to be authorized to use the backoffice"}
        closable={false}
        showDialog={{
          get: !correctUser,
          set: function (value: React.SetStateAction<boolean>): void {},
        }}
        confirmDialogCallback={logout}
      />
    </>
  );
}
