import { Dayjs } from "dayjs";

export class TelemetriesGenerationRequest {
  Device: string;
  Date: Dayjs | null;
  SessionType: string | number;
  Uid: string;
  AveragePow: string;
  ChargeTime: string;
  TotEnergy: string;
  RechargesNumber: string;
  IsLegacy: boolean;

  constructor(
    wbAccessToken: string,
    chargeDate: Dayjs | null,
    sessionType: string | number,
    userUid: string,
    averagePower: string,
    chargeTime: string,
    totEnergy: string,
    chargesNumber: string
  ) {
    this.Device = wbAccessToken;
    this.Date = chargeDate;
    this.SessionType = sessionType;
    this.Uid = userUid;
    this.AveragePow = averagePower;
    this.ChargeTime = chargeTime;
    this.TotEnergy = totEnergy;
    this.RechargesNumber = chargesNumber;
    this.IsLegacy = false;
  }
}
