import { Box } from "@mui/material";
import TableComponent from "./TableComponent";
import { Data, OtaHeadCells } from "./utils";

interface CpoDetailsProps {
    cpoName: string | undefined;
    cpoIsVisible: boolean;
    cpoOtasVersions: Data[] | undefined;
}

export default function CpoDetails(
    props: CpoDetailsProps
) {

    return (
        <Box>
            <Box>
                <label><b>Display Name:</b></label>
                <label>{` ${props.cpoName}`}</label>
            </Box>
            <Box sx={{mt:"10px"}}>
                <label><b>Is Visible:</b></label>
                <label>{` ${props.cpoIsVisible}`}</label>
            </Box>
            <Box sx={{mt:"10px"}}>
                <TableComponent
                    key={"OtaDetail"}
                    tabTitle={"OTAs Versions"}
                    rows={props.cpoOtasVersions == undefined ? [] : props.cpoOtasVersions}
                    headCells={OtaHeadCells}
                    selectionIcon={null}
                    selectionAction={null}
                    rowAction={null}
                    rowActionIcon={null}
                    reload={null}
                />
            </Box>
        </Box>
    );
}