import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import { DeviceDto, ServiceResponseDto } from "../../../Models";
import { AuthService, DeviceControllerUrl, apiURL } from "../../../Services";
import { CircularProgress } from "@mui/material";

interface DeviceAutoCompleteSelectProps {
  setParam: React.Dispatch<React.SetStateAction<string>>;
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
  resetKey?: any;
}

export default function DeviceAutoCompleteSelect(
  props: DeviceAutoCompleteSelectProps
) {
  const [devices, setDevices] = useState<DeviceDto[]>([]);
  const [value, setValue] = useState<string>("");
  const [loading, setIsLoading] = useState(false);
  const authService = new AuthService(apiURL.backofficeApiUrl);

  useEffect(() => {
    if (value.length < 3) {
      setDevices([]);
      return;
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setDevice = (val: DeviceDto | null) => {
    if (!val) return;
    if (props.paramKey === "name") {
      props.setParam(val.name);
    } else if (props.paramKey === "id") {
      props.setParam(val.id);
    }
    setDevices([val]);
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      var res = await authService.GetAsync<ServiceResponseDto<DeviceDto[]>>(
        `${DeviceControllerUrl}/GetDevices?textSearch=${value}`
      );
      setDevices(res.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      getOptionLabel={(dvc) => {
        return dvc.name;
      }}
      id="combo-box-demo"
      key={props.resetKey}
      options={devices}
      onChange={(event: any, newValue: DeviceDto | null) => {
        setDevice(newValue);
      }}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
