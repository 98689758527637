import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

interface DialogComponentProps {
  dialogTitle: string;
  closable: boolean;
  dialogMessage: string;
  showDialog: {
    get: boolean;
    set: React.Dispatch<React.SetStateAction<boolean>>;
  };
  confirmDialogCallback: () => void;
}

export function DialogComponent(props: DialogComponentProps) {
  const handleDialogClose = () => {
    props.showDialog.set(false);
  };
  const handleDialogConfirm = () => {
    props.confirmDialogCallback();
    props.showDialog.set(false);
  };
  return (
    <Dialog
      open={props.showDialog.get}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.closable && <Button onClick={handleDialogClose}>Annulla</Button>}
        <Button onClick={handleDialogConfirm} autoFocus>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogComponent;
