import { useEffect, useState } from "react";
import { TableComponent } from "../../Components";
import {
  Data,
  ManuallyUpdatedWallBoxHeadCells,
  RfidsHeadCells,
  createDataFromManuallyUpdatedWallBoxes,
} from "../../Components/TableComponent/utils";
import IPageProps from "../../Models/IPageProps";
import { Box, Button, TextField } from "@mui/material";
import { WallBoxService } from "../../Services/WallBoxService";
import ClearIcon from "@mui/icons-material/Clear";

function ManualUpdates(props: IPageProps) {
  const [wallBoxRows, setWallBoxRows] = useState<Data[]>([]);
  const [searchField, setSearchField] = useState<string>("");
  const wallBoxService = new WallBoxService();
  const getAllWallboxesManuallyUpdated = async () => {
    try {
      props.setIsLoading(true);
      var res = await wallBoxService.GetManuallyUpdatedWallboxes(searchField);
      if (res.data.length > 0) {
        const responseData = res.data.map((r: string) =>
          createDataFromManuallyUpdatedWallBoxes(r)
        );
        setWallBoxRows(responseData);
      } else {
        props.handleAlertShow("error", "No manually updated wallbox found");
        setWallBoxRows([]);
      }
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      setWallBoxRows([]);
    } finally {
      props.setIsLoading(false);
    }
  };
  const returnWallBoxToNormalUpdate = async (rowId: string) => {
    try {
      props.setIsLoading(true);
      await wallBoxService.DeleteManuallyUpdatedDevice(rowId);
      await getAllWallboxesManuallyUpdated();
      props.handleAlertShow(
        "success",
        "Successfully removed wallbox from manual updates"
      );
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <TextField
          id="outlined-controlled"
          label="Search"
          value={searchField}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchField(event.target.value);
          }}
          fullWidth
        />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            sx={{ margin: "10px" }}
            variant="contained"
            onClick={() => {
              getAllWallboxesManuallyUpdated();
            }}
          >
            Search
          </Button>
          <Button
            sx={{ margin: "10px" }}
            variant="contained"
            onClick={() => {
              setWallBoxRows([]);
              setSearchField("");
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>
      <br />
      {wallBoxRows.length !== 0 && (
        <TableComponent
          tabTitle={"WallBoxes updated manually"}
          rows={wallBoxRows}
          headCells={ManuallyUpdatedWallBoxHeadCells}
          selectionIcon={null}
          selectionAction={null}
          rowAction={returnWallBoxToNormalUpdate}
          rowActionIcon={props.isAdmin ? <ClearIcon /> : null}
          reload={getAllWallboxesManuallyUpdated}
        />
      )}
    </Box>
  );
}

export default ManualUpdates;
