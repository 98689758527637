import { AssetTypeDto } from "./AssetTypeDto";

export class ResetVariablesDto {

    AssetType : AssetTypeDto;
    Keys : string;
    Ids : string[];

    constructor( keys : string, assetType : AssetTypeDto, ids : string[]) {
        this.AssetType = assetType;
        this.Keys = keys;
        this.Ids = ids;
    }
}

export class SelectiveResetVariablesDto {
    Ids : string[];

    constructor(ids : string[]){
        this.Ids = ids;
    }
}