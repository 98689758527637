export enum OtaUpdateResponseStatus {
  OtaUpdateQueued,
  OtaUpdateAlreadyInProgress,
  OtaUpdateGenericError,
  OtaUpdateInvalidFirmware,
  OtaUpdateInvalidSoftware,
  OtaUpdateInvalidCustomerName,
  OtaUpdateInvalidNetworkId,
  OtaUpdateNoDevicesFound,
}
