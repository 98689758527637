import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { RfidDto } from "../../../Models/RfidDto";
import { RfidService } from "../../../Services/RfidService";
import CPODto from "../../../Models/CPODto";
import { CPOService } from "../../../Services/CPOService";

interface CPOAutocompleteSelectProps {
  setParam: React.Dispatch<React.SetStateAction<string>>;
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
}
const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: CPODto) => option.displayName ?? "",
});

const defaultFilter = {
  searchTerm: "",
};

export default function CPOAutocompleteSelect(
  props: CPOAutocompleteSelectProps
) {
  const [cpos, setCPOs] = useState<CPODto[]>([]);
  const [selectedCpo, setSelectedCpo] = useState<CPODto | null>(null);
  const [loading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [searchTerm, setSearchTerm] = useState("");
  const service = new CPOService();

  useEffect(() => {
    loadCPOs();
    //TODO: inserire filtro in array dipendenze una volta supportato
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCPO(selectedCpo);
  }, [selectedCpo]);

  const handleSelectionChange = useCallback(
    (val: CPODto | null) => {
      setSelectedCpo(cpos?.find((x) => x.name === val?.name) ?? null);
      setCPO(val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cpos]
  );
  const handleSearchTermChange = useCallback(
    (val: string) => {
      setSearchTerm(val);
      setFilter({ ...filter, searchTerm: val });
    },
    [filter]
  );

  const setCPO = (val: CPODto | null) => {
    if (val?.name == null || val.name.length === 0) {
      return;
    }
    if (props.paramKey === "id") {
      props.setParam(val?.name);
    } else {
      props.setParam(val?.displayName);
    }
  };

  const loadCPOs = async () => {
    setIsLoading(true);
    try {
      var res = await service.GetCPOs();
      return setCPOs(res?.data.filter((r: CPODto) => r.name !== null));
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={(rfid) => rfid.name ?? ""}
      id="combo-box-demo"
      options={cpos}
      value={selectedCpo}
      onChange={(event, value) => {
        handleSelectionChange(value);
      }}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
