import { FullNetworkDto, NetworkMemberRole, NetworkType } from "../../Models";
import DeviceRealTimeDataDto from "../../Models/DeviceRealTimeDataDto";
import DeviceServerAttributesDto from "../../Models/DeviceServerAttributesDto";
import FullDeviceDto from "../../Models/FullDeviceDto";
import NetworkMemberDto from "../../Models/NetworkMemberDto";

export interface CardRow {
  label: string;
  value: string;
}
export interface CardData {
  title: string;
  rows: CardRow[];
}

export function createNetworkCardData(network: FullNetworkDto): CardData {
  if (network !== undefined) {
    return {
      title: `Network ${network.name}`,
      rows: [
        { label: "Uid", value: network.uid },
        { label: "Address", value: network.address },
        { label: "City", value: network.city },
        { label: "Country", value: network.country },
        { label: "Zip Code", value: network.zipCode },
        { label: "Arera", value: network.arera ? "true" : "false" },
        {
          label: "Three Phase",
          value: network.gridIsThreePhase ? "true" : "false",
        },
        {
          label: "Photovoltaic",
          value: network.isPhotovoltaicThreePhase
            ? "Three Phase"
            : network.isPhotovoltaic
            ? "Single Phase"
            : "No",
        },
        {
          label: "Network Type",
          value: NetworkType[network.networkType]
            .replace(/([A-Z]+)/g, " $1")
            .replace(/^ /, ""),
        },
      ],
    };
  }
  return { title: "Network info", rows: [] };
}
export function createDeviceCardData(device: FullDeviceDto): CardData {
  return {
    title: device.wallboxName,
    rows: [
      { label: "WallBox Type:", value: device.wallboxType },
      {
        label: "Charge Configuration:",
        value: device.autostart
          ? "Free"
          : device.scheduling
          ? "Scheduling"
          : "Locked",
      },
      { label: "Firmware Version:", value: device.stmVersion },
      { label: "Software Version:", value: device.esP32 },
      {
        label: "WiFi Enabled:",
        value: device.wifiEnabled ? "Enabled" : "Disabled",
      },
      { label: "WiFi SSID:", value: device.wifiSSID },
    ],
  };
}
const getFormattedCardTime = (date: Date): string => {
  return (
    date.toLocaleDateString() +
    " " +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
};
export function createRealTimeDeviceCardData(
  deviceData: DeviceRealTimeDataDto
): CardData {
  return {
    title: "Real Time Data",
    rows: [
      {
        label: "Inactivity Alarm:",
        value: getFormattedCardTime(
          new Date(deviceData.serverAttributes.inactivityAlarmTime)
        ),
      },
      {
        label: "Last Seen:",
        value: getFormattedCardTime(
          new Date(deviceData.serverAttributes.lastActivityTime)
        ),
      },
      {
        label: "Last Connection:",
        value: getFormattedCardTime(
          new Date(deviceData.serverAttributes.lastConnectTime)
        ),
      },
      {
        label: "Last Disconnection:",
        value: getFormattedCardTime(
          new Date(deviceData.serverAttributes.lastDisconnectTime)
        ),
      },
      {
        label: "Last Power",
        value: deviceData.clientAttributes.lastPower.toString(),
      },
      {
        label: "Last Energy",
        value: deviceData.clientAttributes.lastEnergy.toString(),
      },
    ],
  };
}
