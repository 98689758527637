import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { CardData, CardRow } from "./utils";

interface CardComponentProps {
  data: CardData | undefined;
}

function CardComponent(props: CardComponentProps | undefined) {
  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={props?.data?.title} />
      {props?.data?.rows === undefined || props.data.rows.length === 0 ? (
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <Typography>Data not available</Typography>
        </CardContent>
      ) : (
        <CardContent>
          <Grid container spacing={1}>
            {props.data.rows.map((data: CardRow) => (
              <Grid container item spacing={4} key={data.label}>
                <Grid item xs={5}>
                  <Typography sx={{ whiteSpace: "break-spaces" }}>
                    {data.label}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {data.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}
export default CardComponent;
