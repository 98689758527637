import React, { useEffect, useState } from "react";
import IPageProps from "../../Models/IPageProps";
import { CPOService } from "../../Services/CPOService";
import { CPOAutoCompleteSelect, TableComponent } from "../../Components";
import { FormControlLabel, Switch } from "@mui/material";
import {
  Data,
  UnsupportedCPODevicesHeadCell,
  createDataFromUnsupportedCPOWallBoxes,
} from "../../Components/TableComponent/utils";

function UnsupportedCPODevicesPage(props: IPageProps) {
  const [cpoName, setCPOName] = useState<string>("");
  const [includeInvalidDeviceProfiles, setIncludeInvalidDeviceProfiles] =
    useState<boolean>(false);
  const [wallBoxRows, setWallBoxRows] = useState<Data[]>([]);
  const cpoService = new CPOService();

  const getUnsupportedCPODevices = async () => {
    try {
      props.setIsLoading(true);
      var unsupportedDeviceResponse = await cpoService.GetUnsupportedCPODevices(
        cpoName,
        includeInvalidDeviceProfiles
      );
      if (unsupportedDeviceResponse.data.length > 0) {
        const responseData = unsupportedDeviceResponse.data.map((r: string) =>
          createDataFromUnsupportedCPOWallBoxes(r)
        );
        setWallBoxRows(responseData);
      } else {
        props.handleAlertShow("error", "No unsupported wallboxes found");
        setWallBoxRows([]);
      }
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  React.useEffect(() => {
    if (cpoName === "") {
      return;
    }
    getUnsupportedCPODevices();
  }, []);
  React.useEffect(() => {
    if (cpoName === "") {
      return;
    }
    getUnsupportedCPODevices();
  }, [cpoName, includeInvalidDeviceProfiles]);
  return (
    <>
      <CPOAutoCompleteSelect
        disabled={false}
        label="Search for CPO"
        handleAlertShow={props.handleAlertShow}
        paramKey="id"
        setParam={setCPOName}
        styleToRender={{ mt: "10px", mb: "20px" }}
      />
      <FormControlLabel
        control={
          <Switch
            onChange={() => {
              setIncludeInvalidDeviceProfiles((prevValue) => !prevValue);
            }}
            checked={includeInvalidDeviceProfiles}
          />
        }
        label="Include devices with unsupported device profile"
      />
      {wallBoxRows.length !== 0 && (
        <TableComponent
          tabTitle={"Unsupported Devices"}
          rows={wallBoxRows}
          headCells={UnsupportedCPODevicesHeadCell}
          selectionIcon={null}
          selectionAction={null}
          rowAction={null}
          rowActionIcon={null}
          reload={getUnsupportedCPODevices}
        />
      )}
    </>
  );
}

export default UnsupportedCPODevicesPage;
