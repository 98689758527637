/** @format */

import { Box, Button, MenuItem, Select } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { DeviceAutoCompleteSelect } from "../../Components";
import { UserAutoCompleteSelect } from "../../Components";
import { IPageProps, TelemetriesGenerationRequest } from "../../Models";
import { TelemetriesGenerationService } from "../../Services";
import RFIDAutocompleteSelect from "../../Components/AutoCompleteSelects/RFIDAutoCompleteSelect/RFIDAutocompleteSelect";

function TelemetriesGeneration(props: IPageProps) {
  const [chargeDate, setChargeDate] = useState<Dayjs | null>(
    dayjs("2022-04-17T15:30")
  );
  const [sessionType, setSessionType] = useState<string | number>(4);
  const [wbAccessToken, setWbAccessToken] = useState<string>("");
  const [userUid, setUserUid] = useState<string>("");
  const [averagePower, setAveragePower] = useState<string>("");
  const [chargeTime, setChargeTime] = useState<string>("");
  const [totEnergy, setTotEnergy] = useState<string>("");
  const [chargesNumber, setChargesNumber] = useState<string>("");

  const telemetriesGenerationService = new TelemetriesGenerationService();

  const confirmCallback = async () => {
    props.setIsLoading(true);
    try {
      await telemetriesGenerationService.Send(
        new TelemetriesGenerationRequest(
          wbAccessToken,
          chargeDate,
          sessionType,
          userUid,
          averagePower,
          chargeTime,
          totEnergy,
          chargesNumber
        )
      );
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const click = async () => {
    await confirmCallback();
  };

  return (
    <div>
      {props.isAdmin && (
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "10px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Charge date"
                value={chargeDate}
                onChange={(newValue: any) => setChargeDate(newValue)}
                sx={{ width: "49%" }}
              />
            </LocalizationProvider>
            <FormControl sx={{ width: "49%" }} size="small">
              <InputLabel id="demo-simple-select-label">
                Session type
              </InputLabel>
              <Select
                value={sessionType}
                label="Session type"
                sx={{ padding: "7px" }}
                onChange={(e) => setSessionType(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
              >
                <MenuItem value={0}>Sconosciuto</MenuItem>
                <MenuItem value={1}>App obsoleta</MenuItem>
                <MenuItem value={2}>Chiave</MenuItem>
                <MenuItem value={3}>RFID</MenuItem>
                <MenuItem value={4}>Autenticazione da app</MenuItem>
                <MenuItem value={5}>Autenticazione con RFID</MenuItem>
                <MenuItem value={6}>Autostart on</MenuItem>
                <MenuItem value={7}>No session type</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <DeviceAutoCompleteSelect
            disabled={false}
            label="Device"
            handleAlertShow={props.handleAlertShow}
            paramKey={"id"}
            setParam={setWbAccessToken}
            styleToRender={{ mt: "10px" }}
          />
          {sessionType === 3 || sessionType === 5 ? (
            <RFIDAutocompleteSelect
              disabled={false}
              label="RFID"
              handleAlertShow={props.handleAlertShow}
              paramKey={"id"}
              setParam={setUserUid}
              styleToRender={{ mt: "10px" }}
            />
          ) : (
            <UserAutoCompleteSelect
              disabled={sessionType !== 4}
              label="Utente"
              handleAlertShow={props.handleAlertShow}
              paramKey={"id"}
              setParam={setUserUid}
              styleToRender={{ mt: "10px" }}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              type="number"
              label="Potenza media (kw)"
              variant="outlined"
              value={averagePower}
              onChange={(event) => setAveragePower(event.target.value)}
              sx={{ width: "49%" }}
            />
            <TextField
              id="outlined-basic"
              type="number"
              label="Tempo di ricarica (secondi)"
              variant="outlined"
              value={chargeTime}
              onChange={(event) => setChargeTime(event.target.value)}
              sx={{ width: "49%" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              type="number"
              label="Energia totale (kw)"
              variant="outlined"
              value={totEnergy}
              onChange={(event) => setTotEnergy(event.target.value)}
              sx={{ width: "49%" }}
            />
            <TextField
              id="outlined-basic"
              type="number"
              label="Numero di ricariche"
              variant="outlined"
              value={chargesNumber}
              onChange={(event) => setChargesNumber(event.target.value)}
              sx={{ width: "49%" }}
            />
          </Box>
          <br />
          <br />
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{ width: 200, p: 1, m: 2, float: "right" }}
              onClick={click}
              variant="outlined"
            >
              Run
            </Button>
          </Box>
        </FormGroup>
      )}
    </div>
  );
}

export default TelemetriesGeneration;
