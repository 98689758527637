/** @format */

import React, { useState } from "react";
import TableComponent from "../../Components/TableComponent/TableComponent";
import {
  Data,
  createDataFromNetworkUsers,
  NetworkUsersHeadCell,
  NetworkAdminsHeadCell,
  createDataFromNetworkAdmins,
  NetworkMembersHeadCell,
  createDataFromNetworkMembers,
  createDataFromNetworkDevices,
  NetworkDevicesHeadCell,
} from "../../Components/TableComponent/utils";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Button from "@mui/material/Button/Button";
import {
  DeviceDto,
  IPageProps,
  NetworkDto,
  NetworkMemberRole,
} from "../../Models";
import { NetworkService } from "../../Services";
import {
  Box,
  ButtonGroup,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { RfidService } from "../../Services/RfidService";
import { RfidDto } from "../../Models/RfidDto";
import {
  DeviceAutoCompleteSelect,
  DialogComponent,
  NetworkAutoCompleteSelect,
  UserAutoCompleteSelect,
} from "../../Components";
import { WallBoxService } from "../../Services/WallBoxService";
import ServiceResponseDto from "../../Models/ServiceResponseDto";
import { DeviceResponseDto } from "../../Models/DeviceResponseDto";
import NetworkMemberDto from "../../Models/NetworkMemberDto";
const enum UserSearchMode {
  SearchByDevice,
  SearchByNetworkUid,
}
function EditNetworks(props: IPageProps) {
  const [currentTab, setCurrentTab] = React.useState("EditNetworks");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");
  const [resetNetworkAutoComplete, setResetNetworkAutoComplete] =
    useState<string>("");
  const [resetDeviceAutoComplete, setResetDeviceAutoComplete] =
    useState<string>("");
  const [resetUserAutoComplete, setResetUserAutoComplete] =
    useState<string>("");

  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [devicesRows, setDevicesRows] = useState<Data[]>([]);
  const [devices, setDevices] = useState<DeviceDto[]>([]);
  const [adminEmail, setAdminEmail] = useState<string>("");
  const [networkList, setNetworkList] = useState<NetworkDto[]>([]);
  const [membersRows, setMembersRows] = React.useState<Data[]>([]);
  const [newAdminEmail, setNewAdminEmail] = React.useState<string>("");
  const [newUserEmail, setNewUserEmail] = React.useState<string>("");
  const [showNetworkWarningDialog, setShowNetworkWarningDialog] =
    useState<boolean>(false);
  const [showRemoveNetworkWarningDialog, setShowRemoveNetworkWarningDialog] =
    useState<boolean>(false);
  const [searchMode, setSearchMode] = useState<UserSearchMode>(
    UserSearchMode.SearchByNetworkUid
  );
  const service = new NetworkService();
  const wallBoxService = new WallBoxService();
  const rfidService = new RfidService();

  const setTab = (newTab: string) => {
    if (newTab === currentTab) {
      return;
    }
    resetState();

    if (newTab === "NetworkUsers") {
      setCurrentTab("NetworkUsers");
    } else if (newTab === "NetworkAdmins") {
      setCurrentTab("NetworkAdmins");
    } else if (newTab === "EditNetworks") {
      setCurrentTab("EditNetworks");
    } else if (newTab === "NetworkMembers") {
      setCurrentTab("NetworkMembers");
    }
  };
  const resetState = () => {
    setMembersRows([]);
    setDevices([]);
    setSelectedNetwork("");
    setNewAdminEmail("");
    setSelectedDevice("");
    setResetNetworkAutoComplete(Math.random().toString());
    setResetDeviceAutoComplete(Math.random().toString());
    setResetUserAutoComplete(Math.random().toString());
  };
  const getSelectedNetworkMembers = async () => {
    if (selectedNetwork === "") {
      return;
    }
    try {
      props.setIsLoading(true);
      var res: ServiceResponseDto<NetworkMemberDto[]> =
        await service.GetNetworkMembers(selectedNetwork);
      const responseData = res.data.map((r: NetworkMemberDto) =>
        createDataFromNetworkMembers(r)
      );
      setMembersRows(responseData);
    } catch (error: any) {
      console.log("getSelectedNetworkUsers returned: " + error.message);
      props.handleAlertShow("error", "No users found in network");
      setMembersRows([]);
    } finally {
      props.setIsLoading(false);
    }
  };
  const getSelectedNetworkUsers = async () => {
    if (selectedNetwork === "") {
      return;
    }
    try {
      props.setIsLoading(true);
      var network = networkList.find((n) => n.uid === selectedNetwork);
      var res = await service.GetNetworkBaseUsers(selectedNetwork);
      const responseData = res.data.map((r: any) =>
        createDataFromNetworkUsers(r)
      );
      setMembersRows(responseData);
    } catch (error: any) {
      console.log("getSelectedNetworkUsers returned: " + error.message);
      props.handleAlertShow("error", "No users found in network");
      setMembersRows([]);
    } finally {
      props.setIsLoading(false);
    }
  };
  const getSelectedDeviceNetwork = async () => {
    if (selectedDevice === "") {
      return;
    }
    try {
      props.setIsLoading(true);
      var res = await wallBoxService.GetDeviceNetwork(selectedDevice);
      setSelectedNetwork(res.data.uid);
    } catch (error: any) {
      props.handleAlertShow("error", "No network found for device");
    } finally {
      props.setIsLoading(false);
    }
  };
  const getSelectedNetworkDevices = async () => {
    try {
      props.setIsLoading(true);
      var res: ServiceResponseDto<DeviceResponseDto[]> =
        await service.GetNetworkDevices(selectedNetwork);
      if (res.data.length === 0) {
        props.handleAlertShow("error", "No devices found in network");
      }
      let devicesDtos = res.data.map(
        (r: DeviceResponseDto) => new DeviceDto(r.serialNumber, r.id)
      );

      setDevices(devicesDtos);
      const responseData = devicesDtos.map((r: any) =>
        createDataFromNetworkDevices(r)
      );
      setDevicesRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", "Couldn't fetch network devices");
      setDevicesRows([]);
    } finally {
      props.setIsLoading(false);
    }
  };

  const loadNetworks = async () => {
    props.setIsLoading(true);
    try {
      var res = await service.GetNetworks();
      setNetworkList(res.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchCurrentTabData = () => {
    loadNetworks();
  };
  React.useEffect(() => {
    fetchCurrentTabData();
  }, [currentTab]);

  React.useEffect(() => {
    if (selectedNetwork === "") {
      return;
    }
    if (currentTab === "NetworkMembers") {
      getSelectedNetworkMembers();
    } else if (currentTab === "NetworkAdmins") {
      getSelectedNetworkAdmins();
    } else if (currentTab === "EditNetworks") {
      getSelectedNetworkDevices();
    } else if (currentTab === "NetworkUsers") {
      getSelectedNetworkUsers();
    }
  }, [selectedNetwork]);
  React.useEffect(() => {
    getSelectedDeviceNetwork();
  }, [selectedDevice]);

  const handlePromotedUserRfids = async (userEmail: any) => {
    var rfids = await rfidService.GetNetworkRfids(selectedNetwork);
    var userRfid = rfids.data.find(
      (x: RfidDto) => x.associatedUser === userEmail
    );
    if (userRfid !== undefined) {
      await rfidService.DeleteUserRfidsFromNetwork(selectedNetwork, userEmail);
      var rfid = new RfidDto(
        userRfid.serialNumber,
        userRfid.name,
        userRfid.createUser,
        userRfid.associatedUser,
        0
      );
      await rfidService.CreateRfid(selectedNetwork, rfid);
    }
  };
  const onEditUser = async (row: any) => {
    var userIndex = membersRows.findIndex((u) => u.firstElement === row);
    if (userIndex === -1) {
      console.log("Selected user is not valid");
      return;
    }
    var user = membersRows[userIndex];
    var role = Number.parseInt(user.id);
    try {
      props.setIsLoading(true);
      var network = networkList.find((n) => n.uid === selectedNetwork);
      if (role === NetworkMemberRole.Administrator) {
        await service.DemoteNetworkAdmin(network!.id, user.firstElement);
        props.handleAlertShow("success", "User has been demoted");
      } else {
        await service.AssignByEmail(user.firstElement, network!.id);
        await handlePromotedUserRfids(row);
        props.handleAlertShow("success", "User has been promoted");
      }
      await getSelectedNetworkMembers();
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  };
  const getSelectedNetworkAdmins = async () => {
    try {
      props.setIsLoading(true);
      var res = await service.GetNetworkAdmins(selectedNetwork);
      const responseData = res.data.map((r: any) =>
        createDataFromNetworkAdmins(r)
      );
      setMembersRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      setMembersRows([]);
    } finally {
      props.setIsLoading(false);
    }
  };

  const onRemoveAdminFromNetwork = async (row: any) => {
    console.log(`Removing admin ${row} from network ${selectedNetwork}`);
    if (membersRows.length === 1) {
      setAdminEmail(row);
      setShowNetworkWarningDialog(true);
    } else {
      removeNetworkAdmin(row);
    }
  };
  const onRemoveUserFromNetwork = async (row: any) => {
    console.log(`Removing user ${row} from network ${selectedNetwork}`);
    removeNetworkUser(row);
  };
  const removeNetworkAdmin = async (adminEmail: string) => {
    try {
      props.setIsLoading(true);
      await service.DeleteNetworkMember(selectedNetwork, adminEmail);
      setMembersRows(membersRows.filter((d) => d.firstElement !== adminEmail));
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const removeNetworkUser = async (userEmail: string) => {
    try {
      props.setIsLoading(true);
      await service.DeleteNetworkMember(selectedNetwork, userEmail);
      setMembersRows(membersRows.filter((d) => d.firstElement !== userEmail));
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const onNetworkDialogConfirm = () => {
    removeNetworkAdmin(adminEmail);
    setAdminEmail("");
  };

  const addUserAsNetworkAdmin = async () => {
    var isUserAnAdmin =
      membersRows.find((admin) => admin.id === newAdminEmail) !== undefined
        ? true
        : false;
    if (isUserAnAdmin) {
      props.handleAlertShow(
        "error",
        "User is already an admin for this network"
      );
      return;
    }
    try {
      props.setIsLoading(true);
      var network = networkList.find((n) => n.uid === selectedNetwork);
      await service.AssignByEmail(newAdminEmail, network!.id);
      await handlePromotedUserRfids(newAdminEmail);
      props.handleAlertShow(
        "success",
        "Added " + newAdminEmail + " to network admins"
      );
      await getSelectedNetworkAdmins();
      setResetUserAutoComplete(Math.random().toString());
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const addUserToNetwork = async () => {
    var isUserInsideNetwork =
      membersRows.find((user) => user.id === newUserEmail) !== undefined
        ? true
        : false;
    if (isUserInsideNetwork) {
      props.handleAlertShow(
        "error",
        "User is already a member of this network"
      );
      return;
    }
    try {
      props.setIsLoading(true);
      var network = networkList.find((n) => n.uid === selectedNetwork);
      await service.AddUserToNetwork(network!.id, newUserEmail);
      props.handleAlertShow("success", "Added " + newUserEmail + " to network");
      await getSelectedNetworkUsers();
      setResetUserAutoComplete(Math.random().toString());
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const onDeleteNetwork = async () => {
    try {
      props.setIsLoading(true);
      var rfids: ServiceResponseDto<RfidDto[]> =
        await rfidService.GetNetworkRfids(selectedNetwork);
      rfids.data.forEach(async (element) => {
        await service.DeleteUserRfidsFromNetwork(
          selectedNetwork,
          element.serialNumber
        );
      });
      await service.DeleteNetwork(selectedNetwork);
      setSelectedNetwork("");
      fetchCurrentTabData();
      props.handleAlertShow("success", "Deleted network");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const deleteWallBoxRfids = async (serialNumber: string) => {
    try {
      await rfidService.DeleteWallboxRfids(serialNumber);
    } catch (error: any) {
      console.log(error);
    }
  };
  const onRemoveDevice = async (row: any) => {
    try {
      props.setIsLoading(true);
      let deviceTbId = devices.find((x) => x.name === row);
      await wallBoxService.RemoveDeviceFromNetwork(deviceTbId!.id);
      await deleteWallBoxRfids(row);
      setDevices(devices.filter((x) => x.id !== deviceTbId!.id));
      setDevicesRows(devicesRows.filter((x) => x.id !== deviceTbId!.id));
      props.handleAlertShow("success", "Deleted device from network");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant={currentTab === "EditNetworks" ? "contained" : "outlined"}
            onClick={() => setTab("EditNetworks")}
          >
            Edit Networks
          </Button>
          <Button
            variant={currentTab === "NetworkMembers" ? "contained" : "outlined"}
            onClick={() => setTab("NetworkMembers")}
          >
            Network Members
          </Button>
          <Button
            variant={currentTab === "NetworkAdmins" ? "contained" : "outlined"}
            onClick={() => setTab("NetworkAdmins")}
          >
            Admins
          </Button>
          <Button
            variant={currentTab === "NetworkUsers" ? "contained" : "outlined"}
            onClick={() => setTab("NetworkUsers")}
          >
            Network Users
          </Button>
        </ButtonGroup>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            mt: "50px",
            p: "2",
            height: "100%",
          }}
        >
          <FormControl sx={{ width: "48%" }} size="small">
            <InputLabel id="update-mode-select-label">Search By</InputLabel>
            <Select
              label="Search user by"
              sx={{ padding: "2%" }}
              labelId="update-mode-select-label"
              id="update-mode-select"
              onChange={(event) => {
                setSearchMode(Number(event.target.value));
                resetState();
              }}
              value={searchMode}
            >
              <MenuItem key={0} value={UserSearchMode.SearchByDevice}>
                {"Device"}
              </MenuItem>
              <MenuItem key={1} value={UserSearchMode.SearchByNetworkUid}>
                {"Network Uid"}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {searchMode === UserSearchMode.SearchByNetworkUid && (
          <Box sx={{ p: 2 }}>
            <Typography gutterBottom>
              Select a network to show the{" "}
              {currentTab === "EditNetworks" ? "devices" : "users"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ width: "49%" }} size="small">
                <NetworkAutoCompleteSelect
                  disabled={false}
                  resetKey={resetNetworkAutoComplete}
                  label="Network"
                  data={{
                    get: networkList,
                    set: setNetworkList,
                  }}
                  handleAlertShow={props.handleAlertShow}
                  paramKey={"networkUid"}
                  setParam={setSelectedNetwork}
                  styleToRender={{ mt: "10px" }}
                />
              </FormControl>
            </Box>
          </Box>
        )}
        {searchMode === UserSearchMode.SearchByDevice && (
          <>
            <Box sx={{ p: 2 }}>
              <Typography gutterBottom>
                Select a device to find the network
              </Typography>
              <FormControl sx={{ width: "49%" }} size="small">
                <DeviceAutoCompleteSelect
                  disabled={false}
                  resetKey={resetDeviceAutoComplete}
                  label="Device"
                  handleAlertShow={props.handleAlertShow}
                  paramKey={"name"}
                  setParam={setSelectedDevice}
                  styleToRender={{ mt: "10px" }}
                />
              </FormControl>
            </Box>
          </>
        )}
        {currentTab === "NetworkMembers" && (
          <>
            {membersRows.length !== 0 && (
              <TableComponent
                tabTitle={
                  searchMode === UserSearchMode.SearchByDevice
                    ? `Network ${selectedNetwork} Users`
                    : "Network Users"
                }
                rows={membersRows}
                headCells={NetworkMembersHeadCell}
                selectionIcon={null}
                selectionAction={null}
                rowAction={onEditUser}
                rowActionIcon={props.isAdmin ? <ModeEditIcon /> : null}
                reload={getSelectedNetworkMembers}
              />
            )}
          </>
        )}
        {currentTab === "NetworkAdmins" && (
          <>
            {selectedNetwork !== "" && (
              <Box sx={{ p: 5 }}>
                <Typography gutterBottom>
                  Select an user to add as admin to the network
                </Typography>
                <FormControl sx={{ width: "49%" }} size="small">
                  <UserAutoCompleteSelect
                    disabled={false}
                    label="User"
                    resetKey={resetUserAutoComplete}
                    handleAlertShow={props.handleAlertShow}
                    paramKey={"email"}
                    setParam={setNewAdminEmail}
                    styleToRender={{ mt: "10px" }}
                  />
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                    p: "2",
                    height: "100%",
                  }}
                >
                  <Button
                    sx={{ width: 200, p: 1, m: 2, float: "right" }}
                    onClick={addUserAsNetworkAdmin}
                    variant="outlined"
                  >
                    Add to Network Admins
                  </Button>
                  <Button
                    sx={{ width: 200, p: 1, m: 2, float: "right" }}
                    onClick={() => getSelectedNetworkAdmins()}
                    variant="outlined"
                  >
                    Reload Admins
                  </Button>
                </Box>
              </Box>
            )}
            {membersRows.length !== 0 && (
              <TableComponent
                key={"Network Admins"}
                tabTitle={"Network Admins"}
                rows={membersRows}
                headCells={NetworkAdminsHeadCell}
                selectionIcon={null}
                selectionAction={null}
                rowAction={onRemoveAdminFromNetwork}
                rowActionIcon={props.isAdmin ? <RemoveCircleIcon /> : null}
                reload={null}
              />
            )}
          </>
        )}
        {currentTab === "EditNetworks" && (
          <Box sx={{ p: 5 }}>
            {selectedNetwork !== "" && (
              <Typography gutterBottom>
                Selected network {selectedNetwork}
              </Typography>
            )}
            {selectedNetwork !== "" && props.isAdmin && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  p: "2",
                  height: "100%",
                }}
              >
                <Button
                  sx={{ width: 200, p: 1, m: 2, float: "right" }}
                  onClick={() => setShowRemoveNetworkWarningDialog(true)}
                  variant="outlined"
                >
                  Remove Network
                </Button>
                <Button
                  sx={{ width: 200, p: 1, m: 2, float: "right" }}
                  onClick={() => getSelectedNetworkDevices()}
                  variant="outlined"
                >
                  Reload Devices
                </Button>
              </Box>
            )}
            {devicesRows.length !== 0 && (
              <TableComponent
                tabTitle="Network devices"
                rows={devicesRows}
                headCells={NetworkDevicesHeadCell}
                selectionIcon={null}
                selectionAction={null}
                rowAction={onRemoveDevice}
                rowActionIcon={props.isAdmin ? <DeleteIcon /> : null}
                reload={null}
              />
            )}
          </Box>
        )}
        {currentTab === "NetworkUsers" && (
          <>
            {selectedNetwork !== "" && (
              <Box sx={{ p: 5 }}>
                <Typography gutterBottom>
                  Select an user to add inside the network
                </Typography>
                <FormControl sx={{ width: "49%" }} size="small">
                  <UserAutoCompleteSelect
                    disabled={false}
                    label="User"
                    resetKey={resetUserAutoComplete}
                    handleAlertShow={props.handleAlertShow}
                    paramKey={"email"}
                    setParam={setNewUserEmail}
                    styleToRender={{ mt: "10px" }}
                  />
                </FormControl>
                <Button
                  sx={{ width: 200, p: 1, m: 2, float: "right" }}
                  onClick={addUserToNetwork}
                  variant="outlined"
                >
                  Add to Network
                </Button>
                <Button
                  sx={{ width: 200, p: 1, m: 2, float: "right" }}
                  onClick={() => getSelectedNetworkUsers()}
                  variant="outlined"
                >
                  Reload Users
                </Button>
              </Box>
            )}
            {membersRows.length !== 0 && (
              <TableComponent
                key={"Network Users"}
                tabTitle={`Network ${selectedNetwork} Users`}
                rows={membersRows}
                headCells={NetworkUsersHeadCell}
                selectionIcon={null}
                selectionAction={null}
                rowAction={onRemoveUserFromNetwork}
                rowActionIcon={props.isAdmin ? <RemoveCircleIcon /> : null}
                reload={null}
              />
            )}
          </>
        )}
      </Box>
      <DialogComponent
        dialogTitle="Warning"
        dialogMessage={
          "Selected network has only one admin, removing it will create an orphaned network...are you sure you want to continue?"
        }
        closable
        showDialog={{
          get: showNetworkWarningDialog,
          set: setShowNetworkWarningDialog,
        }}
        confirmDialogCallback={onNetworkDialogConfirm}
      />
      <DialogComponent
        dialogTitle="Warning"
        dialogMessage={"Are you sure you want to delete the network?"}
        closable
        showDialog={{
          get: showRemoveNetworkWarningDialog,
          set: setShowRemoveNetworkWarningDialog,
        }}
        confirmDialogCallback={onDeleteNetwork}
      />
    </>
  );
}
export default EditNetworks;
