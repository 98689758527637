import { Dispatch, SetStateAction } from "react";
import {
  AuthService,
  DeprecateOtaToolControllerUrl,
  UserControllerUrl,
  apiURL,
} from ".";
import { Buffer } from "buffer";

export class FilesService {
  AuthService: AuthService = new AuthService(apiURL.backofficeApiUrl);

  private async downloadFile(res: any, fileName: string) {
    var blob = new Blob([Buffer.from(res, "base64")], { type: "xls" });
    var a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async exportClients(
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    handleAlertShow: (type: string, message: string) => void
  ) {
    setIsLoading(true);
    await this.AuthService.GetAsync<any>(`${UserControllerUrl}/GetUsersExcel`)
      .then((res) => {
        this.downloadFile(res.data, "exported_users.xlsx");
      })
      .catch((error: Error) => {
        handleAlertShow("error", error.message);
      })
      .finally(() => setIsLoading(false));
  }

  async exportFw(
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    handleAlertShow: (type: string, message: string) => void
  ) {
    setIsLoading(true);
    await this.AuthService.GetAsync<any>(
      `${DeprecateOtaToolControllerUrl}/GetFwExcel`
    )
      .then((res) => {
        this.downloadFile(res.data, "exported_fw.xlsx");
      })
      .catch((error: Error) => {
        handleAlertShow("error", error.message);
      })
      .finally(() => setIsLoading(false));
  }
}
