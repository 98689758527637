export class ChargeSessionDto {
  startTime: Date;
  instantChargePower: number;
  chargeTime: Date;
  chargeEnergy: number;
  userId: string;

  constructor(
    startTime: Date,
    instantChargePower: number,
    chargeTime: Date,
    chargeEnergy: number,
    userId: string
  ) {
    this.startTime = startTime;
    this.instantChargePower = instantChargePower;
    this.chargeTime = chargeTime;
    this.chargeEnergy = chargeEnergy;
    this.userId = userId;
  }
  public getChargeEnergyInkWh = (): number => {
    return this.chargeEnergy / 1e3;
  };
  public getChargeEnergyInkW = (): number => {
    return (this.chargeEnergy * 4) / 1e3;
  };
  private getTicks = (dateObject: Date): number => {
    return dateObject.getTime() * 10000 + 621355968000000000;
  };
}
