import Backdrop from '@mui/material/Backdrop/Backdrop'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import React from 'react'

interface LoaderProps {
    isLoading: boolean
}

function Loader(props : LoaderProps) {
  return (
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
  )
}

export default Loader