export const EVSEState: EVSEStatusType = {
  1: "Standby",
  2: "EVConnectedWaitAuth",
  3: "Charging",
  4: "EVSEError",
  5: "EVConnectedAuthorized",
  6: "EVConnectedWaitPower",
  7: "Preparing",
  8: "Unavailable",
  9: "Finishing",
  10: "Reserved",
};

interface EVSEStatusType {
  [key: string]: string;
}
