import React, { useState } from "react";
import IPageProps from "../../Models/IPageProps";
import { ConfigurationService } from "../../Services";
import PopupInfoDto from "../../Models/PopupInfoDto";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PopupDetailsDto } from "../../Models/PopupDetailsDto";
import {
  AppVersionDto,
  ServiceResponseDto,
} from "../../Models";
enum PopupOccurence {
  Once = 0,
  Recurring,
}
interface PopupInfoState {
  popupKey: string;
  infos: PopupDetailsDto[];
  validFrom: Dayjs;
  validTo: Dayjs;
  occurence: PopupOccurence;
}

function AppConfigurations(props: IPageProps) {
  const [currentTab, setCurrentTab] = React.useState("PopupInfo");
  const [popupInfo, setPopupInfo] = useState<PopupInfoDto>();
  const [dazeCultures, setDazeCultures] = useState<string[]>([]);
  const [currentPopupState, setCurrentPopupState] = useState<PopupInfoState>({
    popupKey: "",
    validFrom: dayjs(),
    validTo: dayjs().add(1, "day"),
    infos: [],
    occurence: PopupOccurence.Recurring,
  });
  const [currentAppVersion, setCurrentAppVersion] = useState<string>("");
  // const [currentOccurence, setCurrentOccurence] = useState<PopupOccurence>(
  //   PopupOccurence.Recurring
  // );
  const service = new ConfigurationService();

  const fetchPopupInfo = async () => {
    try {
      props.setIsLoading(true);
      var cultures: ServiceResponseDto<string[]> = await service.GetDazeSupportedLanguages();
      setDazeCultures(cultures.data);
      currentPopupState.infos = cultures.data.map((x:string) => ({body: "",culture: x, header: "",image: "",}));

      var res: ServiceResponseDto<PopupInfoDto> = await service.GetPopupInfo();
      if (res.data) {
        setPopupInfo(res.data);
        var popupInfo = {
          popupKey: res.data.timing?.key,
          validFrom: dayjs.unix(res?.data.timing?.validFromTimestamp),
          validTo: dayjs.unix(res?.data.timing?.validToTimestamp),
          infos: res.data.infos,
          occurence: res.data.timing.recurrent
            ? PopupOccurence.Recurring
            : PopupOccurence.Once,
        };
        var culturesNoFound = cultures.data.filter((x:string)=>popupInfo.infos.find((y : PopupDetailsDto)=>y.culture==x) ==undefined);
        for (let i = 0; i < culturesNoFound.length; i++) {
          popupInfo.infos.unshift({culture : culturesNoFound[i], header :"",body : "", image:""});
        }
        setCurrentPopupState(popupInfo);
      }
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchAppVersion = async () => {
    try {
      props.setIsLoading(true);
      var appVersion: ServiceResponseDto<AppVersionDto> =
        await service.GetAppVersion();
      if (appVersion.data) {
        setCurrentAppVersion(appVersion.data.version);
      }
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const setTab = (newTab: string) => {
    if (newTab === currentTab) {
      return;
    }
    if (newTab === "PopupInfo") {
      setCurrentTab("PopupInfo");
    } else {
      setCurrentTab("AppVersion");
    }
  };
  const onPopupInfoSave = async () => {
    try {
      props.setIsLoading(true);
      await service.SetPopupInfo({
        infos: currentPopupState.infos,
        timing: {
          key: popupInfo?.timing?.key ?? "",
          validFromTimestamp: currentPopupState.validFrom.unix(),
          validToTimestamp: currentPopupState.validTo.unix(),
          recurrent:
            currentPopupState.occurence === PopupOccurence.Recurring
              ? true
              : false,
        },
      });
      props.handleAlertShow("success", "Saved");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const onAppVersionSave = async () => {
    try {
      props.setIsLoading(true);
      await service.SetAppVersion({
        version: currentAppVersion,
      });
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.handleAlertShow("success", "Saved");
      props.setIsLoading(false);
    }
  };
  const fetchCurrentTabData = () => {
    if (currentTab === "PopupInfo") {
      fetchPopupInfo();
    } else {
      fetchAppVersion();
    }
  };

  React.useEffect(() => {
    fetchCurrentTabData();
  }, [currentTab]);

  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant={currentTab === "PopupInfo" ? "contained" : "outlined"}
            onClick={() => setTab("PopupInfo")}
          >
            Popup Info
          </Button>
          <Button
            variant={currentTab === "AppVersion" ? "contained" : "outlined"}
            onClick={() => setTab("AppVersion")}
          >
            App Version
          </Button>
        </ButtonGroup>
      </Box>
      {currentTab === "PopupInfo" ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              mt: "50px",
              p: "2",
              height: "100%",
            }}
          >
            <Stack spacing={4} sx={{ width: "100%" }}>
              {currentPopupState.infos.map((val, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: "15px",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      value={val.header}
                      label="Header"
                      onChange={(event) =>
                        setCurrentPopupState((prevState) => {
                          const infos = prevState.infos;
                          infos[index] = {
                            ...infos[index],
                            header: event.target.value,
                          };
                          return { ...prevState, infos };
                        })
                      }
                      sx={{ width: "49%" }}
                    />
                    <TextField
                      value={val.image}
                      label="Image"
                      onChange={(event) =>
                        setCurrentPopupState((prevState) => {
                          const infos = prevState.infos;
                          infos[index] = {
                            ...infos[index],
                            image: event.target.value,
                          };
                          return { ...prevState, infos };
                        })
                      }
                      sx={{ width: "49%" }}
                    />
                    <TextField
                      value={val.body}
                      multiline
                      maxRows={2}
                      rows="2"
                      label="Body"
                      onChange={(event) =>
                        setCurrentPopupState((prevState) => {
                          const infos = prevState.infos;
                          infos[index] = {
                            ...infos[index],
                            body: event.target.value,
                          };
                          return { ...prevState, infos };
                        })
                      }
                      sx={{ width: "49%" }}
                    />
                    <FormControl sx={{ width: "24%" }} size="medium">
                      <InputLabel
                        id={"popup-culture-row-type-label" + index}
                        shrink={true}
                      >
                        Language
                      </InputLabel>
                      <Select
                        labelId={"popup-culture-row-type-label" + index}
                        label="Language"
                        id="popup-event-type-select"
                        notched={true}
                        onChange={(event) =>
                          setCurrentPopupState((prevState) => {
                            const infos = prevState.infos;
                            infos[index] = {
                              ...infos[index],
                              culture: event.target.value,
                            };
                            return { ...prevState, infos };
                          })
                        }
                        value={val.culture}
                      >
                        {dazeCultures.map((key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                );
              })}
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              mt: "60px",
            }}
          >
            <FormControl sx={{ width: "49%" }} size="small">
              <InputLabel id="popup-event-type-label" shrink={true}>
                Event Type
              </InputLabel>
              <Select
                labelId="popup-event-type-label"
                label="Event Type"
                id="popup-event-type-select"
                notched={true}
                onChange={(event) =>
                  setCurrentPopupState({
                    ...currentPopupState!,
                    occurence: Number(event.target.value),
                  })
                }
                value={currentPopupState.occurence}
                style={{ height: " 100%" }}
              >
                <MenuItem value={0}>Once</MenuItem>
                <MenuItem value={1}>Recurring</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid from"
                value={currentPopupState.validFrom}
                format="DD/MM/YYYY"
                onChange={(newValue: any) =>
                  setCurrentPopupState({
                    ...currentPopupState!,
                    validFrom: newValue,
                  })
                }
                sx={{ width: "24%" }}
              />
              <DatePicker
                label="Valid to"
                value={currentPopupState.validTo}
                format="DD/MM/YYYY"
                onChange={(newValue: any) => {
                  setCurrentPopupState({
                    ...currentPopupState!,
                    validTo: newValue,
                  });
                }}
                sx={{ width: "24%" }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ width: "100%", my: "25px", py: 4 }}>
            <Button
              sx={{ width: 200, p: 1, mx: 2, float: "right" }}
              onClick={onPopupInfoSave}
              variant="outlined"
              disabled={!props.isAdmin}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mt: "50px" }}>
            <TextField
              label="App Version"
              disabled={!props.isAdmin}
              value={currentAppVersion}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setCurrentAppVersion(event.target.value)}
              sx={{ width: "24%" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{ width: 200, p: 1, m: 2, float: "right" }}
              onClick={onAppVersionSave}
              variant="outlined"
              disabled={!props.isAdmin}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default AppConfigurations;
