import { useEffect, useRef } from "react";

export default function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    if (savedCallback.current === undefined) {
      //Fire the callback immediately then wait x amout of time before firing it
      //again
      callback();
    }
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== 0) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
