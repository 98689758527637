/** @format */

import { NetworkDto, NetworkMemberDto, ServiceResponseDto } from "../Models/";
import { AuthService } from "./AuthService";
import { UserDto, NetworksInMaintenanceDto } from "../Models";
import { UserControllerUrl, NetworkControllerUrl, apiURL } from ".";
import { DeviceResponseDto } from "../Models/DeviceResponseDto";

export class NetworkService {
  authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
  appAPIAuthService: AuthService = new AuthService(apiURL.appApiUrl);

  async GetUsersByName(value: string): Promise<UserDto[]> {
    var res: ServiceResponseDto<UserDto[]> = await this.authService.GetAsync<
      UserDto[]
    >(`${UserControllerUrl}/GetUsers?textSearch=${value}`);
    return res;
  }

  async GetWithoutAdministrates(): Promise<NetworkDto[]> {
    var url = `${NetworkControllerUrl}/GetNetworksWithoutAdministrates`;
    var res: ServiceResponseDto<NetworkDto[]> = await this.authService.GetAsync<
      NetworkDto[]
    >(url);
    return res;
  }
  async GetNetworks(): Promise<ServiceResponseDto<NetworksInMaintenanceDto[]>> {
    var url = `${NetworkControllerUrl}/GetNetworks`;
    var res: ServiceResponseDto<NetworkDto[]> = await this.authService.GetAsync<
      NetworkDto[]
    >(url);
    return res;
  }
  async GetNetworksInMaintenance(
    olderThanHours: number
  ): Promise<NetworksInMaintenanceDto[]> {
    var url = `${NetworkControllerUrl}/GetNetworksInMaintenance?olderThan=${olderThanHours}`;
    var res: ServiceResponseDto<NetworksInMaintenanceDto[]> =
      await this.authService.GetAsync<NetworksInMaintenanceDto[]>(url);
    return res;
  }

  async Assign(userId: string, netId: string) {
    var url = `${NetworkControllerUrl}/AssignAdmin?userId=${userId}&netId=${netId}`;
    await this.authService.PostAsyncNoContent(url);
  }
  async AssignByEmail(userEmail: string, netId: string) {
    var url = `${NetworkControllerUrl}/AssignAdminByEmail?userEmail=${userEmail}&netId=${netId}`;
    await this.authService.PostAsyncNoContent(url);
  }

  async AddUserToNetwork(networkId: string, userEmail: string) {
    var url = `${NetworkControllerUrl}/AddUserToNetwork?networkId=${networkId}&userEmail=${userEmail}`;
    await this.authService.PostAsyncNoContent(url);
  }

  async DeleteNetworkInMaintenance(assetId: string) {
    var url = `${NetworkControllerUrl}/DeleteNetworkInMaintenance`;
    await this.authService.PostAsync(url, assetId);
  }

  async GetAllUsers(): Promise<UserDto[]> {
    var url = `${UserControllerUrl}/GetAllUsers`;
    var res: ServiceResponseDto<UserDto[]> = await this.authService.GetAsync<
      UserDto[]
    >(url);
    return res;
  }

  async GetNetworkMembers(
    networkUid: string
  ): Promise<ServiceResponseDto<NetworkMemberDto[]>> {
    var url = `networks/${networkUid}/members`;
    var res: ServiceResponseDto<NetworkMemberDto[]> =
      await this.appAPIAuthService.GetAsync<
        ServiceResponseDto<NetworkMemberDto[]>
      >(url);
    return res;
  }
  async GetNetworkBaseUsers(
    networkUid: string
  ): Promise<ServiceResponseDto<UserDto[]>> {
    var url = `networks/${networkUid}/baseUsers`;
    var res: ServiceResponseDto<UserDto[]> =
      await this.appAPIAuthService.GetAsync<ServiceResponseDto<UserDto[]>>(url);
    return res;
  }

  async GetNetworkDevices(
    networkUid: string
  ): Promise<ServiceResponseDto<DeviceResponseDto[]>> {
    var url = `networks/${networkUid}/wbs`;
    var res: DeviceResponseDto[] = await this.appAPIAuthService.GetAsync<
      ServiceResponseDto<DeviceResponseDto[]>
    >(url);
    return res;
  }

  async GetNetworkAdmins(
    networkUid: string
  ): Promise<ServiceResponseDto<UserDto[]>> {
    var url = `networks/${networkUid}/admins`;
    var res: ServiceResponseDto<UserDto[]> =
      await this.appAPIAuthService.GetAsync<ServiceResponseDto<UserDto[]>>(url);
    return res;
  }
  async DeleteNetworkMember(
    networkUid: string,
    userEmail: string
  ): Promise<void> {
    var url = `networks/${networkUid}/members/${userEmail}/?forceDelete=true`;
    await this.appAPIAuthService.DeleteAsync(url);
  }
  async DeleteNetwork(networkUid: string): Promise<void> {
    var url = `networks/${networkUid}/?forceDelete=true`;
    await this.appAPIAuthService.DeleteAsync(url);
  }
  async DemoteNetworkAdmin(
    networkId: string,
    adminEmail: string
  ): Promise<void> {
    var url = `${NetworkControllerUrl}/DemoteNetworkAdmin?networkId=${networkId}&adminEmail=${adminEmail}`;
    await this.authService.DeleteAsync(url);
  }
  async DeleteUserRfidsFromNetwork(
    networkUid: string,
    userEmail: string
  ): Promise<ServiceResponseDto> {
    return await this.appAPIAuthService.DeleteAsync<ServiceResponseDto>(
      `networks/${networkUid}/members/${userEmail}/rfids`
    );
  }
  async DeleteRfidFromNetwork(
    networkUid: string,
    rfidSerialNumber: string
  ): Promise<ServiceResponseDto> {
    return await this.appAPIAuthService.DeleteAsync<ServiceResponseDto>(
      `networks/${networkUid}/rfids/${rfidSerialNumber}`
    );
  }
  async GetNetworkUsersByWallBoxSerialNumber(
    networkId: string,
    serialNumber: string
  ): Promise<ServiceResponseDto<NetworkMemberDto[]>> {
    var url = `${NetworkControllerUrl}/GetNetworkUsersByWallBox?networkId=${networkId}&serialNumber=${serialNumber}`;
    var res: ServiceResponseDto<NetworkMemberDto[]> =
      await this.authService.GetAsync<NetworkMemberDto[]>(url);
    return res;
  }
}
