import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { RfidDto } from "../../../Models/RfidDto";
import { RfidService } from "../../../Services/RfidService";

interface RFIDAutocompleteSelectProps {
  setParam: React.Dispatch<React.SetStateAction<string>>;
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
}
const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: RfidDto) => option.serialNumber ?? "",
});

const defaultFilter = {
  searchTerm: "",
};

export default function RFIDAutocompleteSelect(
  props: RFIDAutocompleteSelectProps
) {
  const [rfids, setRfids] = useState<RfidDto[]>([]);
  const [selectedRfid, setSelectedRfid] = useState<RfidDto | null>(null);
  const [loading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [searchTerm, setSearchTerm] = useState("");
  const service = new RfidService();

  useEffect(() => {
    loadRfids();
    //TODO: inserire filtro in array dipendenze una volta supportato
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setRfid(selectedRfid);
  }, [selectedRfid]);

  const handleSelectionChange = useCallback(
    (val: RfidDto | null) => {
      setSelectedRfid(
        rfids?.find((x) => x.serialNumber === val?.serialNumber) ?? null
      );
      setRfid(val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rfids]
  );
  const handleSearchTermChange = useCallback(
    (val: string) => {
      setSearchTerm(val);
      setFilter({ ...filter, searchTerm: val });
    },
    [filter]
  );

  const setRfid = (val: RfidDto | null) => {
    if (val?.serialNumber == null || val.serialNumber.length === 0) {
      return;
    }
    if (props.paramKey === "id") {
      props.setParam(val?.serialNumber);
    } else if (props.paramKey === "serialNumber") {
      props.setParam(val?.serialNumber);
    } else if (props.paramKey === "associatedUser") {
      props.setParam(val?.associatedUser);
    }
  };

  const loadRfids = async () => {
    setIsLoading(true);
    try {
      var res = await service.GetRfids();
      return setRfids(res?.data.elements.filter((r: any) => r.serial !== null));
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={(rfid) => rfid.serialNumber ?? ""}
      id="combo-box-demo"
      options={rfids}
      value={selectedRfid}
      onChange={(event, value) => {
        handleSelectionChange(value);
      }}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
