import { AuthService, apiURL } from ".";
import { PageDto, ServiceResponseDto } from "../Models";
import { RfidDto } from "../Models/RfidDto";

export class RfidService {
  authService: AuthService = new AuthService(apiURL.appApiUrl);
  async CreateRfid(networkUid: string, rfid: RfidDto): Promise<void> {
    await this.authService.PostAsync(`networks/${networkUid}/rfids`, rfid);
  }
  async GetRfids(): Promise<ServiceResponseDto<PageDto<RfidDto>>> {
    return await this.authService.GetAsync<
      ServiceResponseDto<PageDto<RfidDto>>
    >(`rfids`);
  }
  async GetNetworkRfids(
    networkUid: string
  ): Promise<ServiceResponseDto<RfidDto[]>> {
    return await this.authService.GetAsync<
      ServiceResponseDto<PageDto<RfidDto>>
    >(`networks/${networkUid}/rfids`);
  }
  async DeleteRfid(serialNumber: string): Promise<ServiceResponseDto> {
    return await this.authService.DeleteAsync<ServiceResponseDto<RfidDto[]>>(
      `rfids/${serialNumber}`
    );
  }
  async DeleteUserRfidsFromNetwork(
    serialNumber: string,
    userEmail: string
  ): Promise<ServiceResponseDto> {
    return await this.authService.DeleteAsync<ServiceResponseDto>(
      `networks/${serialNumber}/members/${userEmail}/rfids`
    );
  }
  async DeleteWallboxRfids(serialNumber: string): Promise<ServiceResponseDto> {
    return await this.authService.DeleteAsync<ServiceResponseDto>(
      `wallboxes/${serialNumber}/rfids`
    );
  }
}
