import React, { useEffect, useState } from "react";
import IPageProps from "../../Models/IPageProps";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  CardComponent,
  DeviceAutoCompleteSelect,
  TableComponent,
  useInterval,
} from "../../Components";
import { NetworkService } from "../../Services";
import { WallBoxService } from "../../Services/WallBoxService";
import ServiceResponseDto from "../../Models/ServiceResponseDto";
import { ChargeSessionDto } from "../../Models";
import FullNetworkDto from "../../Models/FullNetworkDto";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  CardData,
  createDeviceCardData,
  createNetworkCardData,
  createRealTimeDeviceCardData,
} from "../../Components/CardComponent/utils";
import {
  Data,
  IsErrInBlackList,
  NetworkMembersHeadCellNoEdit,
  createDataFromNetworkMembers,
  unixTimestampToDateTime,
} from "../../Components/TableComponent/utils";
import FullDeviceDto from "../../Models/FullDeviceDto";
import ReplayIcon from "@mui/icons-material/Replay";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeviceRealTimeDataDto from "../../Models/DeviceRealTimeDataDto";
import NetworkMemberDto from "../../Models/NetworkMemberDto";
import RadialBarChart from "../../Components/Charts/RadialBarChart";
import CircleIcon from "@mui/icons-material/Circle";
import PowerBarChart from "../../Components/Charts/PowerBarChart";
import { EVSEState } from "../../Models/EVSEState";

interface ChartData {
  label: string;
  value: number;
  barColors?: string[];
  backgroundColor?: string;
}

function Dashboard(props: IPageProps) {
  const [pollingInterval, setPollingInterval] = useState<number>(5);
  const [membersRows, setMembersRows] = React.useState<Data[]>([]);
  const min1500 = useMediaQuery("(min-width:1500px)");
  const over2370 = useMediaQuery("(min-width:2370px)");
  const deviceService = new WallBoxService();
  const networkService = new NetworkService();

  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [networkData, setNetworkData] = useState<CardData>();
  const [deviceData, setDeviceData] = useState<CardData>();
  const [deviceRealTimeData, setDeviceRealTimeData] = useState<CardData>();
  const [isDeviceActive, setIsDeviceActive] = useState<boolean>(false);
  const [XAxisRange, setXAxisRange] = useState<number[]>([]);
  const [YAxisRange, setYAxisRange] = useState<number[]>([]);
  const [graphData, setGraphData] = useState<any>();
  const [energyGraphData, setEnergyGraphData] = useState<ChartData[]>();
  const [lastPwData, setLastPwData] = useState<any>(undefined);
  const [isWallBoxThreePhase, setIsWallBoxThreePhase] =
    useState<boolean>(false);
  const [telemetrySessionStart, setTelemetrySessionStart] = useState<Dayjs>(
    dayjs().subtract(1, "week")
  );
  const [telemetrySessionEnd, setTelemetrySessionEnd] = useState<Dayjs>(
    dayjs()
  );

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [energyGraphData]);

  //Returns the Uid of the network the device is currently in or empty
  //if it's not in any network
  const fetchDeviceNetwork = async (): Promise<string> => {
    var result = "";
    try {
      props.setIsLoading(true);
      var network: ServiceResponseDto<FullNetworkDto> =
        await deviceService.GetDeviceNetwork(selectedDevice);
      var networkData = createNetworkCardData(network.data);
      setNetworkData(networkData);
      result = network.data.uid;
    } catch (error: any) {
      if (!IsErrInBlackList(error.errCode))
        props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
      return result;
    }
  };
  const fetchDeviceData = async () => {
    try {
      props.setIsLoading(true);
      var device: ServiceResponseDto<FullDeviceDto> =
        await deviceService.GetDeviceData(selectedDevice);
      var deviceData = createDeviceCardData(device.data);
      setIsWallBoxThreePhase(device.data.evseIsThreePhase);
      setDeviceData(deviceData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const fetchDeviceRealTimeData = async () => {
    try {
      var data: ServiceResponseDto<DeviceRealTimeDataDto> =
        await deviceService.GetDeviceRealTimeData(selectedDevice);
      setIsDeviceActive(data.data.serverAttributes.active);
      var deviceData = createRealTimeDeviceCardData(data.data);
      setDeviceRealTimeData(deviceData);
      setLastPwData({
        lastPower: data.data.clientAttributes.lastPower,
        lastSessionId: unixTimestampToDateTime(
          data.data.clientAttributes.lastSessionId
        ),
        lastStatus: EVSEState[data.data.clientAttributes.lastStatus],
        dataLabel: "Last Power",
      });
      setEnergyGraphData([
        {
          label: "ICh1",
          value: data.data.clientAttributes.lastChargingCurrentInstantL1,
          barColors: ["#F2B200"],
          backgroundColor: "#223AA3",
        },
        {
          label: "ICh1",
          value: data.data.clientAttributes.lastChargingCurrentInstantL2,
          barColors: ["#F2B200"],
          backgroundColor: "#223AA3",
        },
        {
          label: "ICh1",
          value: data.data.clientAttributes.lastChargingCurrentInstantL3,
          barColors: ["#F2B200"],
          backgroundColor: "#223AA3",
        },
        {
          label: "CT1",
          value: data.data.clientAttributes.lastSupplyGridInstantCurrentL1,
          barColors: ["#F2B200"],
          backgroundColor: "#7182c9",
        },
        {
          label: "CT2",
          value: data.data.clientAttributes.lastSupplyGridInstantCurrentL2,
          barColors: ["#F2B200"],
          backgroundColor: "#7182c9",
        },
        {
          label: "CT3",
          value: data.data.clientAttributes.lastSupplyGridInstantCurrentL3,
          barColors: ["#F2B200"],
          backgroundColor: "#7182c9",
        },
      ]);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
    }
  };
  const fetchDeviceTelemetryData = async () => {
    try {
      props.setIsLoading(true);
      var telemetry: ServiceResponseDto<ChargeSessionDto[]> =
        await deviceService.GetDeviceTelemetryData(
          selectedDevice,
          telemetrySessionStart.valueOf(),
          telemetrySessionEnd.valueOf()
        );
      if (telemetry.data.length === 0) {
        setGraphData(undefined);
        return;
      }
      //Map it to the real object, otherwise we are not
      //able to access his properties
      var realTelemetryData = telemetry.data
        .flat()
        .map(
          (p) =>
            new ChargeSessionDto(
              p.startTime,
              p.instantChargePower,
              p.chargeTime,
              p.chargeEnergy,
              p.userId
            )
        );

      var maxEnergyValue =
        Math.max(
          ...realTelemetryData.map((o: ChargeSessionDto) =>
            o.getChargeEnergyInkW()
          )
        ) +
        Math.max(
          ...realTelemetryData.map((o: ChargeSessionDto) =>
            o.getChargeEnergyInkW()
          )
        ) *
          0.1;
      var suggestedMaxEnergyValue = isWallBoxThreePhase ? 22 : 7.4;
      var YEnd = Math.max(maxEnergyValue, suggestedMaxEnergyValue);
      setYAxisRange([0, YEnd]);
      var minValueTimespan = new Date(realTelemetryData[0].startTime);
      var maxValueTimespan = new Date(
        realTelemetryData[realTelemetryData.length - 1].startTime
      );
      minValueTimespan.setSeconds(minValueTimespan.getSeconds() - 5);
      setXAxisRange([
        telemetrySessionStart.valueOf(),
        telemetrySessionEnd.valueOf(),
      ]);
      setGraphData(
        realTelemetryData.map((data) => ({
          name: data.startTime,
          Power: data.chargeEnergy,
        }))
      );
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchUsersInNetwork = async (networkId: string) => {
    try {
      props.setIsLoading(true);
      var networkUsers: NetworkMemberDto[] = (
        await networkService.GetNetworkMembers(networkId)
      ).data;
      var userRows = networkUsers.map((r: NetworkMemberDto) =>
        createDataFromNetworkMembers(r)
      );
      setMembersRows(userRows);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const fetchAllData = async () => {
    if (selectedDevice === "") {
      setPollingInterval(0);
      return;
    }
    await fetchDeviceData();
    await fetchDeviceRealTimeData();
    var networkUid = await fetchDeviceNetwork();
    if (networkUid !== "") {
      await fetchUsersInNetwork(networkUid);
    }
    await fetchDeviceTelemetryData();
    setPollingInterval(5);
  };
  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  useInterval(async () => {
    if (selectedDevice === "") {
      return;
    }
    await fetchDeviceRealTimeData();
  }, pollingInterval * 1000);

  const onChangePollingEvent = (event: any) => {
    setPollingInterval(event.target.value);
  };

  return (
    <>
      <Typography>
        Select a device to show an overview of the current state
      </Typography>
      <DeviceAutoCompleteSelect
        disabled={false}
        label="Devices"
        handleAlertShow={props.handleAlertShow}
        paramKey={"name"}
        setParam={setSelectedDevice}
        styleToRender={{ mt: "10px" }}
      />
      <Box
        sx={{
          mt: "50px",
          height: "100%",
        }}
      >
        {selectedDevice !== "" && (
          <>
            <Typography>Filter Energy Data:</Typography>
            <Box
              sx={{
                display: "flex",
                mt: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Session Started"
                  value={telemetrySessionStart}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  onChange={(newValue: any) =>
                    setTelemetrySessionStart(newValue)
                  }
                  sx={{ width: "49%" }}
                />
                <DateTimePicker
                  label="Session Ended"
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={telemetrySessionEnd}
                  onChange={(newValue: any) => setTelemetrySessionEnd(newValue)}
                  sx={{ width: "49%" }}
                />
              </LocalizationProvider>
              <Button
                sx={{ width: 200, p: 1, m: 2, float: "right" }}
                onClick={() => fetchDeviceTelemetryData()}
                variant="outlined"
                startIcon={<FilterAltIcon />}
              >
                Filter
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "90%",
              }}
            >
              <FormControl sx={{ width: "10%" }} size="small">
                <InputLabel id="polling-interval-select-label" shrink={true}>
                  Polling Interval
                </InputLabel>
                <Select
                  labelId="polling-interval-select-label"
                  id="polling-interval-select"
                  label={"Polling Interval"}
                  notched={true}
                  value={pollingInterval}
                  onChange={onChangePollingEvent}
                >
                  <MenuItem value={0}>Disabled</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button
                sx={{ width: 200, p: 1, m: 2, float: "right" }}
                onClick={() => fetchAllData()}
                variant="outlined"
                startIcon={<ReplayIcon />}
              >
                Reload
              </Button>
            </Box>
            <Grid2 container spacing={2}>
              <Grid2 container spacing={1}>
                <Grid2 xs={12} md={4}>
                  <CardComponent data={networkData} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardComponent data={deviceData} />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <CardComponent data={deviceRealTimeData} />
                </Grid2>
              </Grid2>
              <Grid2
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                {deviceRealTimeData !== undefined && (
                  <Grid2 xs={12}>
                    <Card sx={{ width: "100%" }}>
                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "16px",
                        }}
                      >
                        {" "}
                        <Typography fontSize={"24px"} sx={{ marginRight: 2 }}>
                          {"Current Status: "}
                        </Typography>
                        <CircleIcon
                          sx={{
                            color: isDeviceActive ? "#39a17a" : "#d43a49",
                            marginRight: 1,
                          }}
                        />
                        <Typography
                          fontSize={20}
                          sx={{ color: isDeviceActive ? "#39a17a" : "#d43a49" }}
                        >
                          {isDeviceActive ? "Active" : "Not Active"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid2>
                )}
              </Grid2>
              <Grid2
                xs={12}
                lg={8}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {deviceRealTimeData !== undefined && (
                  <Card sx={{ width: "100%" }}>
                    <CardHeader
                      sx={{ width: "100%" }}
                      title="Charging Current"
                    />
                    <CardContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {energyGraphData !== undefined ? (
                        <Grid2
                          container
                          spacing={{ xs: 1 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {energyGraphData?.map((x, i) => (
                            <Grid2 xs={2} sm={4} md={4} key={i}>
                              <RadialBarChart
                                series={x.value}
                                labels={x.label}
                                barColors={x.barColors}
                                backgroundColor={x.backgroundColor}
                              />
                            </Grid2>
                          ))}
                        </Grid2>
                      ) : (
                        <Typography>Data not available</Typography>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Grid2>
              <Grid2
                xs={12}
                lg={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {deviceRealTimeData !== undefined && (
                  <Card sx={{ width: "100%" }}>
                    <CardHeader sx={{ width: "100%" }} title="" />
                    <CardContent
                      sx={{
                        width: "100%",
                        height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {lastPwData !== undefined ? (
                        <>
                          <Typography>{`Last Status: ${lastPwData.lastStatus}`}</Typography>
                          <PowerBarChart
                            series={lastPwData.lastPower}
                            labels={[lastPwData.dataLabel]}
                            isWallBoxThreePhase={isWallBoxThreePhase}
                          />
                          <Typography sx={{ marginBottom: 2 }}>
                            Last Session:{" "}
                          </Typography>
                          <Typography>{lastPwData.lastSessionId}</Typography>
                        </>
                      ) : (
                        <Typography>Data not available</Typography>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Grid2>
              <Grid2 xs={12}>
                <Card>
                  <CardHeader title="Energy" />
                  {YAxisRange.length > 0 ? (
                    <CardContent
                      sx={{ maxHeight: "400px", minHeight: "250px" }}
                    >
                      <ResponsiveContainer
                        height="80%"
                        width="99%"
                        aspect={min1500 ? (over2370 ? 5 : 4) : 3}
                      >
                        <LineChart
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          data={graphData}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <Brush
                            dataKey="name"
                            height={35}
                            stroke="#F2B200"
                            tickFormatter={(value: any, index: number) => {
                              var date = new Date(value);
                              return (
                                date.toLocaleDateString() +
                                " " +
                                date.toLocaleTimeString()
                              );
                            }}
                          >
                            <></>
                          </Brush>
                          <XAxis
                            dataKey="name"
                            domain={XAxisRange}
                            tickFormatter={(value: string | number | Date) => {
                              var date = new Date(value);
                              return (
                                date.getHours() +
                                ":" +
                                (date.getMinutes() < 10 ? "0" : "") +
                                date.getMinutes()
                              );
                            }}
                          />
                          <YAxis
                            label={{
                              value: "kWh/15m",
                              position: "insideLeft",
                              angle: -90,
                              dy: -10,
                            }}
                            domain={YAxisRange}
                          />
                          <Tooltip
                            labelFormatter={(value: any) => {
                              return value;
                            }}
                            formatter={(value: number) => {
                              return value + "kW";
                            }}
                          />
                          <Legend color="#F2B200" />
                          <Line
                            type="natural"
                            dataKey={"Power"}
                            stroke="#223AA3"
                            strokeWidth={"3px"}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardContent>
                  ) : (
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                      }}
                    >
                      <Typography>Data not available</Typography>
                    </CardContent>
                  )}
                </Card>
              </Grid2>
              <Grid2 xs={12}>
                <Card>
                  <CardHeader title="Network Members" />
                  <CardContent>
                    {membersRows.length !== 0 ? (
                      <TableComponent
                        tabTitle=""
                        rows={membersRows}
                        headCells={NetworkMembersHeadCellNoEdit}
                        selectionIcon={null}
                        selectionAction={null}
                        rowAction={null}
                        rowActionIcon={null}
                        reload={null}
                      />
                    ) : (
                      <Typography>No Users found</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </>
        )}
      </Box>
    </>
  );
}
export default Dashboard;
