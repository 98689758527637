import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, Typography } from "@mui/material";
import { UserAutoCompleteSelect } from "../../Components";
import { IPageProps, SetUserPrivilegeRequestDto } from "../../Models";
import { NetworkService, UserService } from "../../Services";

function Users(props: IPageProps) {
  const userService = new UserService();
  const [superAdminEmail, setSuperAdminEmail] = useState<string>("");

  const promoteUserToSuperAdmin = async () => {
    if (superAdminEmail === "") {
      props.handleAlertShow("error", "No user selected");
    }
    props.setIsLoading(true);
    debugger;
    try {
      var request: SetUserPrivilegeRequestDto = {
        userEmail: superAdminEmail,
      };
      await userService.SetUserAsSuperAdmin(request);
      props.handleAlertShow("success", "User has been set as Super Admin");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <div>
      {props.isAdmin && (
        <>
          <Typography>Select an user to set as Super Admin</Typography>
          <FormGroup>
            <UserAutoCompleteSelect
              disabled={false}
              label="User"
              handleAlertShow={props.handleAlertShow}
              paramKey={"email"}
              setParam={setSuperAdminEmail}
              styleToRender={{ mt: "10px" }}
            />
          </FormGroup>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{ width: 200, p: 1, m: 2, float: "right" }}
              onClick={promoteUserToSuperAdmin}
              variant="outlined"
            >
              Run
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}

export default Users;
