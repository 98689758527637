/** @format */

import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import Button from "@mui/material/Button/Button";
import {
  allUsersHeadCells,
  Data,
  FwsHeadCells,
  createDataFromUsers,
  createDeviceOtaDto,
} from "../../Components/TableComponent/utils";
import TableComponent from "../../Components/TableComponent/TableComponent";
import { IPageProps } from "../../Models";
import {
  DeprecateOtaService,
  FilesService,
  NetworkService,
} from "../../Services";

function Files(props: IPageProps) {
  const [filesToDownload, setFilesToDownload] = React.useState("Users");

  const [usersRows, setUsersRows] = React.useState<Data[]>([]);
  const [fwsRows, setFwsRows] = React.useState<Data[]>([]);

  const userService = new NetworkService();
  const fwsService = new DeprecateOtaService();
  const fileService = new FilesService();

  async function getAllUsers() {
    try {
      props.setIsLoading(true);
      const response: any = await userService.GetAllUsers();
      const responseData = response.data.map((r: any) =>
        createDataFromUsers(r)
      );
      setUsersRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }
  async function getFws() {
    try {
      props.setIsLoading(true);
      const response: any = await fwsService.GetDeviceOtas();
      const responseData = response.data.map((r: any) => createDeviceOtaDto(r));
      setFwsRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (filesToDownload === "Users") {
      getAllUsers();
    } else {
      getFws();
    }
  }, [filesToDownload]);

  const exportFile = async () => {
    if (filesToDownload === "Users") {
      await fileService.exportClients(
        props.setIsLoading,
        props.handleAlertShow
      );
    } else {
      await fileService.exportFw(props.setIsLoading, props.handleAlertShow);
    }
  };

  return (
    <>
      <Box>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            variant={filesToDownload === "Users" ? "contained" : "outlined"}
            onClick={() => setFilesToDownload("Users")}
          >
            download users
          </Button>
          <Button
            variant={filesToDownload === "Fws" ? "contained" : "outlined"}
            onClick={() => setFilesToDownload("Fws")}
          >
            download fws
          </Button>
        </ButtonGroup>
        {filesToDownload === "Users" ? (
          <TableComponent
            key={"Users"}
            tabTitle={"Users"}
            rows={usersRows}
            headCells={allUsersHeadCells}
            selectionIcon={null}
            selectionAction={null}
            rowAction={null}
            rowActionIcon={null}
            reload={getAllUsers}
          />
        ) : (
          <TableComponent
            key={"Firmwares"}
            tabTitle={"Firmwares"}
            rows={fwsRows}
            headCells={FwsHeadCells}
            selectionIcon={null}
            selectionAction={null}
            rowAction={null}
            rowActionIcon={null}
            reload={getFws}
          />
        )}
        <IconButton
          onClick={exportFile}
          sx={{
            width: "200px",
            height: "60px",
            fontSize: "15px",
            borderRadius: "10px",
          }}
          color="primary"
        >
          <label
            style={{
              marginRight: "10px",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
          >
            download
          </label>
          <DownloadIcon fontSize="medium"></DownloadIcon>
        </IconButton>
      </Box>
    </>
  );
}

export default Files;
