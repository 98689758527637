/** @format */

import Box from "@mui/material/Box/Box";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BadgeIcon from "@mui/icons-material/Badge";

interface AccountBarComponentProps {
  account: string | undefined;
  email: string | undefined;
  role: any;
  logout: any;
}

function AccountBarComponent(props : AccountBarComponentProps) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      boxShadow={1}
      sx={{
        mb: "2%",
        border: 1,
        borderColor: "divider",
        backgroundColor: "white",
        padding: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "5%",
      }}
    >
      <Box sx={{ ml: "2%" }}>
        <p>
          <b>{props.account}</b>
        </p>
      </Box>
      <Box>
        <Tooltip title="User profile">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AccountCircleIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", ml: "20px", mr: "15px" }}
        >
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            {props.account}
          </p>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", ml: "15px", mr: "15px" }}
        >
          <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
            <BadgeIcon fontSize="small" sx={{ mr: "15px" }} />
            {props.role}
          </ListItemIcon>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: "15px",
            mr: "15px",
            mt: "10px",
          }}
        >
          <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
            <MailOutlineIcon fontSize="small" sx={{ mr: "15px" }} />
            {props.email}
          </ListItemIcon>
        </Box>
        <MenuItem onClick={async () => await props.logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default AccountBarComponent;
