import { Box, Typography } from "@mui/material/";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import React from "react";
import OtaUpdateStatusDto from "../../Models/OtaUpdateStatusDto";
import LinearProgressBarWithLabel from "../LinearProgressBarWithLabel/LinearProgressBarWithLabel";
import { timestampToDate } from "../TableComponent/utils";

interface UpdateProgressOverlayProps {
  visible?: boolean;
  otaStatus: OtaUpdateStatusDto | null;
}

function UpdateProgressOverlay(props: UpdateProgressOverlayProps) {
  const timeStampToDate = (timestamp: number): string => {
    var date = new Date(Number(timestamp));
    return (
      date.toLocaleDateString() +
      " " +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes()
    );
  };
  return (
    <>
      {props.otaStatus !== null && (
        <Box>
          <Typography>An Update is in progress</Typography>
          <Typography>
            Update was started by {props.otaStatus.createdUser} at{" "}
            {timeStampToDate(props.otaStatus.createdTimestamp)}
          </Typography>{" "}
          <Typography>
            {props.otaStatus.numberOfWallBoxesToUpdate} devices will be updated
          </Typography>
          <Typography>{props.otaStatus.description}</Typography>
          <LinearProgressBarWithLabel
            value={
              props.otaStatus.numberOfWallBoxesToUpdate === 0
                ? 0
                : (props.otaStatus.currentNumberOfAffectedWallBoxes /
                    props.otaStatus.numberOfWallBoxesToUpdate) *
                  100
            }
          />
        </Box>
      )}
    </>
  );
}

export default UpdateProgressOverlay;
