import axios, { AxiosError } from "axios";
import ServiceResponseDto, {
  GenericServiceResponseDto,
} from "./ServiceResponseDto";

export class CustomError {
  statusCode: number;
  errCode: number;
  message: string;

  constructor(error: any) {
    let statusCode = -1;
    let errCode = -1;
    let message = "";
    if (error !== null) {
      if (axios.isAxiosError(error)) {
        const response = error.response;
        if (error.code === "ERR_NETWORK") {
          statusCode = -1;
          errCode = -1;
          message = "Network Error";
        } else {
          if (response) {
            statusCode = response.status;
            let serviceResponseData: GenericServiceResponseDto = response.data;
            if (
              serviceResponseData.errors !== null &&
              serviceResponseData.errors.length === 1
            ) {
              errCode = response.data.errors[0].code;
              message = response.data.errors[0].message;
            } else {
              //NOTE:101 is internal for server error
              errCode = 101;
              message = serviceResponseData.message;
            }
          }
        }
      }
    }
    this.statusCode = statusCode;
    this.errCode = errCode;
    this.message = message;
  }
}
