import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import { DataDto } from "../../Models";

interface AutoCompleteSelectProps {
  paramKey?: string;
  setParam: any;
  handleAlertShow: any;
  disabled: boolean;
  label: string;
  styleToRender: any;
  data: DataDto[];
}

function AutoCompleteSelect(props: AutoCompleteSelectProps) {
  const [value, setValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataDto[]>(props.data);

  useEffect(() => {
    if (value === "") {
      props.setParam(value);
      setFilteredData(props.data);
      return;
    }
    var filtered = props.data.filter((data) => data.name.includes(value));
    setFilteredData(filtered);
  }, [value]);

  const setData = (val: DataDto | null) => {
    if (!val) return;
    if (props.paramKey !== null && props.paramKey === "name") {
      props.setParam(val.name);
    } else {
      props.setParam(val.id);
    }
    setFilteredData([val]);
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      getOptionLabel={(dvc) => {
        return dvc.name;
      }}
      id="combo-box-demo"
      options={filteredData}
      onChange={(_: any, newValue: DataDto | null) => {
        setData(newValue);
      }}
      sx={props.styleToRender}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    />
  );
}

export default AutoCompleteSelect;
