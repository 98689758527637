import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { AuthService, DeviceControllerUrl, apiURL } from "../../../Services";
import { NetworkControllerUrl } from "../../../Services";
import { UserControllerUrl } from "../../../Services";

interface AttributeAutoCompleteSelectProps {
  setIsLoading: any;
  setParam: React.Dispatch<string>;
  assetType: any;
  handleAlertShow: any;
  paramKey: string;
  disabled: boolean;
  label: string;
  styleToRender: any;
  assetId: string;
  initialValue?: string;
}

export default function AttributeAutoCompleteSelect(
  props: AttributeAutoCompleteSelectProps
) {
  const [attributes, setAttributes] = useState<string[]>([]);
  const [value, setValue] = useState<string>("");
  const authService = new AuthService(apiURL.backofficeApiUrl);

  useEffect(() => {
    if (value.length < 3) {
      setAttributes([]);
      return;
    }
    loadAttributes();
  }, [value]);

  useEffect(() => {
    if (props.initialValue === undefined || props.initialValue === "") {
      return;
    }

    if (props.initialValue.length < 3) {
      setAttributes([]);
      return;
    }
    setValue(props.initialValue);
  }, []);
  const setAttribute = (val: string | null) => {
    var currentValue = "";
    if (val) {
      currentValue = val;
    }
    setValue(currentValue);
    props.setParam(currentValue);
    setAttributes([currentValue]);
  };
  var getAssetUri = (): string => {
    if (props.assetType === "AppUser") {
      return `${UserControllerUrl}/GetUserAttributes/?textSearch=${value}&userId=${props.assetId}`;
    } else if (props.assetType === "Network") {
      return `${NetworkControllerUrl}/GetNetworkAttributes/?textSearch=${value}&networkId=${props.assetId}`;
    } else {
      return `${DeviceControllerUrl}/GetDeviceAttributes/?textSearch=${value}&deviceId=${props.assetId}`;
    }
  };
  const loadAttributes = async () => {
    props.setIsLoading(true);
    try {
      var res: any = await authService.GetAsync<string[]>(`${getAssetUri()}`);
      setAttributes(res.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disablePortal
      getOptionLabel={(attribute) => {
        return attribute;
      }}
      id="combo-box-demo"
      options={attributes}
      onChange={(event: any, newValue: string | null) => {
        setAttribute(newValue);
      }}
      sx={props.styleToRender}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    />
  );
}
