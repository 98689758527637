export class AssetTypeDto {
    Type : string; //DEVICE or ASSET
    Asset : string; //AppUser or Network or ''
    Name : string; 

    constructor(type : string, asset : string, name : string) {
        this.Type = type;
        this.Asset = asset;
        this.Name = name;
    }
}

export const getAssetTypeDtos : () => AssetTypeDto[] = () => {

    var assets : AssetTypeDto[] = [];

    assets.push(new AssetTypeDto("DEVICE", "", "Device"));
    assets.push(new AssetTypeDto("ASSET", "AppUser", "AppUser"));
    assets.push(new AssetTypeDto("ASSET", "Network","Network"));

    return assets;

}

export const getDeviceAssetTypeDto : () => AssetTypeDto = () => {

    return new AssetTypeDto("DEVICE", "", "Device");
}