/** @format */
import React, { useState } from "react";
import TableComponent from "../../Components/TableComponent/TableComponent";
import {
  Data,
  createDataFromRfids,
  RfidsHeadCells,
} from "../../Components/TableComponent/utils";
import Button from "@mui/material/Button/Button";
import { IPageProps } from "../../Models";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { RfidService } from "../../Services/RfidService";
import { RfidDto } from "../../Models/RfidDto";
import RFIDAutocompleteSelect from "../../Components/AutoCompleteSelects/RFIDAutoCompleteSelect/RFIDAutocompleteSelect";

function Rfid(props: IPageProps) {
  const [rfidRows, setRfidRows] = React.useState<Data[]>([]);
  const [selectedRfids, setSelectedRfids] = React.useState<readonly string[]>(
    []
  );
  const [baseRfidRows, setBaseRfidRows] = React.useState<Data[]>([]);
  const [rfidSerial, setRfidSerial] = React.useState<string>("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getRfids();
  }, [0]);

  React.useEffect(() => {
    if (rfidSerial != "") {
      const filteredRows = baseRfidRows.filter((obj) => obj.id === rfidSerial);
      setRfidRows(filteredRows);
    }
  }, [rfidSerial]);

  const service = new RfidService();

  async function getRfids() {
    try {
      props.setIsLoading(true);
      const response: RfidDto[] = (await service.GetRfids()).data.elements;
      const responseData = response.map((r) => createDataFromRfids(r));
      setRfidRows(responseData);
      setBaseRfidRows(responseData);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
      props.setIsLoading(false);
    } finally {
      props.setIsLoading(false);
    }
  }

  const deleteRfid = async () => {
    props.setIsLoading(true);
    var errors: string[] = [];
    for (let i = 0; i < selectedRfids.length; i++) {
      const response = await service.DeleteRfid(selectedRfids[i]);
      response.errors.map((err: string) => errors.push(err));
    }
    await getRfids();

    if (errors.length > 0) {
      props.handleAlertShow("error", errors.join(", "));
    } else {
      props.handleAlertShow("success", "L'operazione è andata a buon fine");
    }
    props.setIsLoading(false);
    setIsDeleteDialogOpen(false);
  };
  const openConfirmDeleteDialog = (selected: readonly string[]) => {
    setIsDeleteDialogOpen(true);
    setSelectedRfids(selected);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "50%" }}>
          <RFIDAutocompleteSelect
            disabled={false}
            label="Search for serial number"
            handleAlertShow={props.handleAlertShow}
            paramKey="id"
            setParam={setRfidSerial}
            styleToRender={{ mt: "10px" }}
          />
        </Box>
        <Box
          sx={{
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setRfidRows(baseRfidRows);
              setRfidSerial("");
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>

      <br></br>
      <TableComponent
        tabTitle={"Rfid list"}
        rows={rfidRows}
        headCells={RfidsHeadCells}
        selectionIcon={props.isAdmin ? <DeleteIcon /> : null}
        selectionAction={props.isAdmin ? openConfirmDeleteDialog : null}
        rowAction={null}
        rowActionIcon={null}
        reload={getRfids}
      />
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Attenzione</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare gli elementi selezionati? L'operazione
            è irreversibile
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteDialogOpen(false);
            }}
          >
            Annulla
          </Button>
          <Button onClick={deleteRfid} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default Rfid;
