import { Box, useMediaQuery } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexcharts from "react-apexcharts";

export interface RadialChartOptions {
  series: number | number[];
  labels: string | string[];
  barColors?: string[];
  backgroundColor?: string;
}

const RadialBarChart = (props: RadialChartOptions) => {
  const matches = useMediaQuery("(min-width:1500px)");
  const labels: string[] = Array.isArray(props.labels)
    ? props.labels
    : [props.labels];
  const valuesToArray: number[] = Array.isArray(props.series)
    ? props.series
    : [props.series];
  const series = valuesToArray.map((value) => getValuePercentage(value));

  function getValuePercentage(value: number) {
    return value / 10 / 32;
  }
  function getOriginalValue(value: number) {
    return ((value / 100) * 32).toFixed(3);
  }

  const options: ApexOptions = {
    colors: props.barColors ?? ["#F2B200"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 3,
          background: props.backgroundColor ?? "#F2B200",
          size: "70%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "20px",
            fontFamily: "Arial",
            fontWeight: 400,
            show: true,
          },
          value: {
            color: "#fff",
            fontSize: "20px",
            fontFamily: "Arial",
            fontWeight: 400,
            show: true,
            formatter: function (val: number) {
              return `${valuesToArray[0] / 1000}A`;
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#f5e2ae"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    grid: {
      padding: {
        top: -23,
        bottom: -23,
        right: 50,
        left: 20,
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    stroke: {
      lineCap: "round",
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 1500,
        options: {
          plotOptions: {},
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            },
          },
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: 2 }}>
      <ReactApexcharts
        options={options}
        series={series}
        type="radialBar"
        height={matches ? 250 : 200}
        width={"100%"}
      />
    </Box>
  );
};
export default RadialBarChart;
