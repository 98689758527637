import axios, { AxiosError } from "axios";
import { useAuth } from "oidc-react";
import { CustomError } from "../Models/CustomError";

export class AuthService {
  auth = useAuth();
  apiURL: string;
  constructor(apiURL: string) {
    this.apiURL = apiURL;
  }

  async GetAsync<Type>(url: string): Promise<Type> {
    var res: Type;
    var accessToken = this.getAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    try {
      const response = await axios.get<Type>(`${this.apiURL}${url}`, config);
      res = response.data;
    } catch (error: any) {
      throw new CustomError(error);
    }

    return res;
  }

  async PostAsync(url: string, data: any) {
    const content = JSON.stringify(data);
    var accessToken = this.getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    await axios.post(`${this.apiURL}${url}`, content, config).catch((error) => {
      throw new CustomError(error);
    });
  }
  async PostAsyncAndReturn<Type>(url: string, data: any): Promise<Type> {
    const content = JSON.stringify(data);
    var accessToken = this.getAccessToken();
    var res: Type;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post<Type>(
        `${this.apiURL}${url}`,
        content,
        config
      );
      res = response.data;
    } catch (error: any) {
      throw new CustomError(error);
    }
    return res;
  }
  async PutAsyncAndReturn<Type>(url: string, data: any): Promise<Type> {
    const content = JSON.stringify(data);
    var accessToken = this.getAccessToken();
    var res: Type;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.put<Type>(
        `${this.apiURL}${url}`,
        content,
        config
      );
      res = response.data;
    } catch (error: any) {
      throw new CustomError(error);
    }
    return res;
  }
  async PatchAsync<Type>(url: string, data: any): Promise<Type> {
    const content = JSON.stringify(data);
    var accessToken = this.getAccessToken();
    var res: Type;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.patch<Type>(
        `${this.apiURL}${url}`,
        content,
        config
      );
      res = response.data;
    } catch (error: any) {
      throw new CustomError(error);
    }
    return res;
  }
  async DeleteAsync<Type>(url: string): Promise<Type> {
    var res: Type;
    var accessToken = this.getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.delete<Type>(`${this.apiURL}${url}`, config);
      res = response.data;
    } catch (error: any) {
      throw new CustomError(error);
    }
    return res;
  }

  async PostAsyncNoContent(url: string) {
    var accessToken = this.getAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    await axios.post(`${this.apiURL}${url}`, {}, config).catch((error) => {
      throw new CustomError(error);
    });
  }

  private getAccessToken(): string {
    return this.auth.userData?.access_token ?? "";
  }
}
