import React, { useEffect, useState } from "react";
import IPageProps from "../../Models/IPageProps";
import { FirmwareUpdateSearchMode, NetworkDto } from "../../Models";
import DeviceProfileDto from "../../Models/DeviceProfileDto";
import { OtaService } from "../../Services/OtaService";
import { NetworkService } from "../../Services";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  DeviceAutoCompleteSelect,
  DialogComponent,
  TableComponent,
  UpdateProgressOverlay,
  useInterval,
} from "../../Components";
import FailedFirmwareUpdateDto from "../../Models/FailedFirmwareUpdateDto";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Data,
  FailedFirmwareHeadCells,
  createDataFromFailedFirmwareUpdateDto,
} from "../../Components/TableComponent/utils";
import OtaUpdateStatusDto from "../../Models/OtaUpdateStatusDto";
import ServiceResponseDto from "../../Models/ServiceResponseDto";
import { WallBoxService } from "../../Services/WallBoxService";
function FailedFirmwareUpdate(props: IPageProps) {
  const [networkList, setNetworkList] = useState<NetworkDto[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");
  const [selectedDeviceProfile, setSelectedDeviceProfile] =
    useState<string>("");
  const [deviceProfileList, setDeviceProfileList] = useState<
    DeviceProfileDto[]
  >([]);
  const [searchMode, setSearchMode] = useState<FirmwareUpdateSearchMode>(
    FirmwareUpdateSearchMode.SearchAll
  );
  const [failedUpdateDevices, setFailedUpdateDevices] = useState<Data[]>([]);

  const [selectedDevice, setSelectedDevice] = useState<string>("");

  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = useState<number>(5);
  const [otaStatus, setOtaStatus] = useState<OtaUpdateStatusDto | null>(null);
  const otaService = new OtaService();
  const wallBoxService = new WallBoxService();
  const networkService = new NetworkService();

  const fetchNetworks = async () => {
    try {
      props.setIsLoading(true);
      var response: ServiceResponseDto<NetworkDto[]> =
        await networkService.GetNetworks();
      setNetworkList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const fetchDeviceProfiles = async () => {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<DeviceProfileDto[]> =
        await otaService.GetDeviceProfiles();
      setDeviceProfileList(response.data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  useEffect(() => {
    if (searchMode === FirmwareUpdateSearchMode.SearchByNetworkId) {
      fetchNetworks();
    } else if (
      searchMode === FirmwareUpdateSearchMode.SearchByDeviceProfileId
    ) {
      fetchDeviceProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMode]);
  const getSearchParamByUpdateMode = (): string => {
    switch (searchMode) {
      case FirmwareUpdateSearchMode.SearchByWallBoxName:
        return selectedDevice;
      case FirmwareUpdateSearchMode.SearchByNetworkId:
        return selectedNetwork;
      case FirmwareUpdateSearchMode.SearchByDeviceProfileId:
        return selectedDeviceProfile;
      case FirmwareUpdateSearchMode.SearchAll:
      default:
        return "";
    }
  };
  const searchFailedDevices = async () => {
    try {
      props.setIsLoading(true);
      const response: ServiceResponseDto<FailedFirmwareUpdateDto[]> =
        await otaService.GetFailedUpdateDevices(
          getSearchParamByUpdateMode(),
          searchMode
        );
      if (response.data.length === 0) {
        props.handleAlertShow("error", "No Failed device(s) found");
      }
      var data = response.data.map((r: FailedFirmwareUpdateDto) =>
        createDataFromFailedFirmwareUpdateDto(r)
      );
      setFailedUpdateDevices(data);
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const onDialogConfirm = async () => {
    //Perform search by given params
    await searchFailedDevices();
  };
  const onRetryUpdate = async (deviceName: string) => {
    try {
      props.setIsLoading(true);
      await wallBoxService.RetryDeviceUpdate(deviceName);
      await searchFailedDevices();
      props.handleAlertShow("success", "Started a new firmware update");
    } catch (error: any) {
      props.handleAlertShow("error", error.message);
    } finally {
      props.setIsLoading(false);
    }
  };
  const checkUpdateStatus = async () => {
    try {
      const response: ServiceResponseDto<OtaUpdateStatusDto> =
        await otaService.CheckOtaUpdateStatus();
      setOtaStatus(response.data);
    } catch (error: any) {
      setPollingInterval(0);
      setOtaStatus(null);
    }
  };
  useInterval(async () => {
    await checkUpdateStatus();
  }, pollingInterval * 1000);
  return (
    <>
      {otaStatus !== null ? (
        <UpdateProgressOverlay otaStatus={otaStatus} />
      ) : (
        <>
          <Typography>Search for failed firmware update</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              mt: "50px",
              p: "2",
              height: "100%",
            }}
          >
            <FormControl sx={{ width: "48%" }} size="small">
              <InputLabel id="update-mode-select-label">
                Search Filters
              </InputLabel>
              <Select
                label="Update Mode"
                sx={{ padding: "2%" }}
                labelId="update-mode-select-label"
                id="update-mode-select"
                onChange={(event) => {
                  setFailedUpdateDevices([]);
                  setSearchMode(Number(event.target.value));
                }}
                value={searchMode}
              >
                <MenuItem key={0} value={FirmwareUpdateSearchMode.SearchAll}>
                  {"All"}
                </MenuItem>
                <MenuItem
                  key={1}
                  value={FirmwareUpdateSearchMode.SearchByWallBoxName}
                >
                  {"Device"}
                </MenuItem>
                <MenuItem
                  key={2}
                  value={FirmwareUpdateSearchMode.SearchByNetworkId}
                >
                  {"Network"}
                </MenuItem>
                <MenuItem
                  key={3}
                  value={FirmwareUpdateSearchMode.SearchByDeviceProfileId}
                >
                  {"Device Profile"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormGroup>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                mt: "60px",
                alignItems: "center",
              }}
            >
              {searchMode === FirmwareUpdateSearchMode.SearchByNetworkId && (
                <FormControl sx={{ width: "49%" }} size="small">
                  <InputLabel id="network-select-label">Network</InputLabel>

                  <Select
                    labelId="network-select-label"
                    id="network-select"
                    label="Network"
                    onChange={(event) => setSelectedNetwork(event.target.value)}
                    value={selectedNetwork}
                  >
                    {networkList.map((network) => {
                      return (
                        <MenuItem key={network.id} value={network.id}>
                          {network.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {searchMode ===
                FirmwareUpdateSearchMode.SearchByDeviceProfileId && (
                <FormControl sx={{ width: "49%" }} size="small">
                  <InputLabel id="device-profile-select-label">
                    Device Profile
                  </InputLabel>

                  <Select
                    labelId="device-profile-select-label"
                    id="device-profile-select"
                    label="Device Profile"
                    onChange={(event) =>
                      setSelectedDeviceProfile(event.target.value)
                    }
                    value={selectedDeviceProfile}
                  >
                    {deviceProfileList.map((deviceProfile) => {
                      return (
                        <MenuItem
                          key={deviceProfile.id}
                          value={deviceProfile.id}
                        >
                          {deviceProfile.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
            {searchMode === FirmwareUpdateSearchMode.SearchByWallBoxName && (
              <DeviceAutoCompleteSelect
                disabled={false}
                label="Device"
                handleAlertShow={props.handleAlertShow}
                paramKey={"name"}
                setParam={setSelectedDevice}
                styleToRender={{ mt: "10px" }}
              />
            )}
            <Box sx={{ width: "100%", my: "10px", mb: "20px" }}>
              <Button
                sx={{ width: 200, p: 1, mx: 2, float: "right" }}
                variant="outlined"
                onClick={() => setShowWarningDialog(true)}
              >
                Search
              </Button>
            </Box>
          </FormGroup>

          {failedUpdateDevices.length > 0 && (
            <TableComponent
              tabTitle={"Failed Device Firmware Updates"}
              rows={failedUpdateDevices}
              headCells={FailedFirmwareHeadCells}
              selectionIcon={null}
              selectionAction={null}
              rowAction={onRetryUpdate}
              rowActionIcon={props.isAdmin ? <ReplayIcon /> : null}
              reload={searchFailedDevices}
            />
          )}
        </>
      )}
      <DialogComponent
        dialogTitle="Warning"
        dialogMessage={
          "Search may take a while...are you sure you want to continue?"
        }
        closable
        showDialog={{
          get: showWarningDialog,
          set: setShowWarningDialog,
        }}
        confirmDialogCallback={onDialogConfirm}
      />
    </>
  );
}

export default FailedFirmwareUpdate;
