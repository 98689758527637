import Alert, { AlertColor } from '@mui/material/Alert/Alert'
import React, { Dispatch, SetStateAction } from 'react'

interface AlertComponentProps {
    showAlert : {
        get: boolean;
        set: Dispatch<SetStateAction<boolean>>;
    };
    alertType : AlertColor | undefined;
    alertText : string | undefined;
}

export default function AlertComponent(props : AlertComponentProps) {

    const handleAlertClose = () => {
        props.showAlert.set(false);
    }

  return (
          <>{props.showAlert.get && (
            <Alert sx={{width: "90%", marginLeft: "5%", marginRight: "5%", bottom: 0}} severity={props.alertType} onClose={handleAlertClose}>
              {props.alertText}
            </Alert>
          )}
          </>
  )
}
