import { DeviceControllerUrl, apiURL } from ".";
import { ChargeSessionDto } from "../Models";
import CustomerProvisionDto from "../Models/CustomerProvisionDto";
import DeviceRealTimeDataDto from "../Models/DeviceRealTimeDataDto";
import FullDeviceDto from "../Models/FullDeviceDto";
import FullNetworkDto from "../Models/FullNetworkDto";
import PageDto from "../Models/PageDto";
import ServiceResponseDto, {
  GenericServiceResponseDto,
} from "../Models/ServiceResponseDto";
import { AuthService } from "./AuthService";

export class WallBoxService {
  authService: AuthService = new AuthService(apiURL.backofficeApiUrl);
  appAPIAuthService: AuthService = new AuthService(apiURL.appApiUrl);

  async GetDeviceNetwork(
    deviceSerialNumber: string
  ): Promise<ServiceResponseDto<FullNetworkDto>> {
    var url = `wallboxes/${deviceSerialNumber}/network`;
    var res: ServiceResponseDto<FullNetworkDto> =
      await this.appAPIAuthService.GetAsync<ServiceResponseDto<FullNetworkDto>>(
        url
      );
    return res;
  }
  async RemoveDeviceFromNetwork(deviceId: string) {
    var url = `${DeviceControllerUrl}/DeleteDevice?deviceId=${deviceId}`;
    await this.authService.DeleteAsync(url);
  }
  async CreateDevices(customerProvisionData: CustomerProvisionDto) {
    var url = `${DeviceControllerUrl}/CreateDevices`;
    await this.authService.PostAsync(url, customerProvisionData);
  }

  async GetDeviceData(
    deviceSerialNumber: string
  ): Promise<ServiceResponseDto<FullDeviceDto>> {
    var url = `wallboxes/${deviceSerialNumber}`;
    return await this.appAPIAuthService.GetAsync<
      ServiceResponseDto<FullDeviceDto>
    >(url);
  }
  async GetDeviceTelemetryData(
    deviceSerialNumber: string,
    sessionId: number,
    chargesEndTs: number
  ): Promise<ServiceResponseDto<ChargeSessionDto[]>> {
    var url = `wallboxes/${deviceSerialNumber}/telemetries/${sessionId}/?chargesEndTs=${chargesEndTs}`;
    return await this.appAPIAuthService.GetAsync<
      ServiceResponseDto<ChargeSessionDto[]>
    >(url);
  }
  async GetDeviceRealTimeData(
    deviceSerialNumber: string
  ): Promise<ServiceResponseDto<DeviceRealTimeDataDto>> {
    var url = `${DeviceControllerUrl}/${deviceSerialNumber}/realTimeAttributes`;
    return await this.authService.GetAsync<
      ServiceResponseDto<DeviceRealTimeDataDto>
    >(url);
  }
  async GetManuallyUpdatedWallboxes(
    serialNumberLike: string
  ): Promise<ServiceResponseDto<string[]>> {
    return await this.appAPIAuthService.GetAsync<ServiceResponseDto<string[]>>(
      `manualupdatewallboxes?serialNumber=${serialNumberLike}`
    );
  }
  async DeleteManuallyUpdatedDevice(
    serialNumber: string
  ): Promise<ServiceResponseDto<string[]>> {
    return await this.appAPIAuthService.DeleteAsync<
      ServiceResponseDto<string[]>
    >(`manualupdatewallboxes/${serialNumber}`);
  }
  async RetryDeviceUpdate(
    deviceSerialNumber: string
  ): Promise<GenericServiceResponseDto> {
    return await this.appAPIAuthService.PostAsyncAndReturn<GenericServiceResponseDto>(
      `wallboxes/${deviceSerialNumber}/retryUpdate`,
      {}
    );
  }
}
