import { IOta } from "./";

export class DeprecateOtaRequest{

    otas : IOta[];


    constructor(otas : IOta[]){
        this.otas = otas;
    }
    
}