import "./App.css";
import { Home } from "./Pages";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { devTheme, prodTheme } from "./theme";
import { Theme } from "@mui/material/styles/createTheme";
import { AuthProvider, AuthProviderProps } from "oidc-react";

function App() {
  const getTheme: () => Theme = () => {
    if (process.env.REACT_APP_ENV === "prod") {
      return prodTheme;
    } else {
      return devTheme;
    }
  };

  const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_AUTORITHY,
    clientId: process.env.REACT_APP_CLIENT,
    scope: process.env.REACT_APP_SCOPES,
    redirectUri: process.env.REACT_APP_API_REDIRECT_URL,
    automaticSilentRenew: true,
    onSignIn() {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
  };

  return (
    <AuthProvider {...oidcConfig}>
      <ThemeProvider theme={getTheme()}>
        <Home />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
